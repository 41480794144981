import { Component, Input, inject, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentStore } from '../../../services/content.store';
import { SectionComponent } from '../section.component';
import { SectionModel } from '../../../models/nucleus.model';

@Component({
	selector: 'wyzeweb-mobile-accordion',
	standalone: true,
	imports: [CommonModule, SectionComponent],
	templateUrl: './mobile-accordion.component.html',
	styleUrl: './mobile-accordion.component.scss'
})
export class MobileAccordionComponent {
	@Input() data: any;

	contentStore = inject(ContentStore);

	openPanels = signal<string[]>([]);

	addOpenPanel(panel: string): void {
		this.openPanels.update(panels => [...panels, panel]);
	}

	removeOpenPanel(panel: string): void {
		this.openPanels.update(panels => panels.filter(item => item !== panel));
	}

	isPanelOpen(panel: string): boolean {
		return this.openPanels().includes(panel);
	}

	get groupedSections(): any {
		return this.contentStore.groupedSections()?.filter((item, index) => index > 0);
	}

	getTitleClass(variant: string, name: string): string {
		return this.isPanelOpen(name)
			? `section-closed__title section-closed__title--${variant}`
			: 'section-closed__title';
	}

	appendCardsToSection(section: SectionModel): SectionModel {
		if (this.contentStore.cards().length > 0)
			section.content.items = this.contentStore
				.cards()
				.filter(card => (section.category === 'all' ? card : card.variant.includes(section.category)));
		return section;
	}
}
