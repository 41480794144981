import { Component, Input } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';

// TODO: set this up with cloudflare loaders to generate preconnects for LCP images

@Component({
	selector: 'wyzeweb-optimized-image',
	standalone: true,
	imports: [NgOptimizedImage],
	templateUrl: './optimized-image.component.html'
})
export class OptimizedImageComponent {
	@Input() source = '';
	@Input() alt = '';
	@Input() width = '';
	@Input() height = '';
	@Input() priority = false; // will enable lazy loading on all images not priority

	//TODO: set up logic to replace height/width responsively
}
