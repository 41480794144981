import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EventEmitter, Output } from '@angular/core';
import { OptimizedImageComponent } from '../../../optimized-image/optimized-image.component';
import { SiteLinkModel } from '../../../../models/nucleus.model';
import { ListConfig } from '../../../../interfaces/side-nav.interfaces';
import { ICON } from '@i-wyze/ui-shared-library';

@Component({
	selector: 'wyzeweb-nav-list',
	standalone: true,
	imports: [CommonModule, OptimizedImageComponent],
	templateUrl: './nav-list.component.html',
	styleUrl: './nav-list.component.scss'
})
export class NavListComponent {
	public readonly ICON = ICON;
	@Input() listConfig: ListConfig = { title: '', links: [] };
	@Input() currentRoute = '';
	@Output() subMenuActivated: EventEmitter<{ title: string; newGroup: string }> = new EventEmitter<{
		title: string;
		newGroup: string;
	}>();

	public activateSubMenu(link: SiteLinkModel): void {
		this.subMenuActivated.emit({ title: link.display_name, newGroup: link.sub_link_group! });
	}

	public goBack(): void {
		this.subMenuActivated.emit({ title: '', newGroup: '' });
	}

	public listItemClass(link: SiteLinkModel): string {
		return `nav-list__item ${link.link === this.currentRoute ? 'nav-list__item--active' : ''}`;
	}

	public getLinkClass(link: SiteLinkModel): string {
		return `nav-list__link ${
			link.link === this.currentRoute || /\d/.test(link.display_name) ? 'nav-list__link--active' : ''
		}`;
	}
}
