<div class="information-item">
	<div class="information-item__img-container">
		<uis-optimized-image
			[source]="data.desktop_image"
			[height]="data.image_height"
			[width]="data.image_width"></uis-optimized-image>
	</div>
	@if(data.title) {
	<p class="information-item__title" [innerHtml]="data.title"></p>
	}
	<p class="information-item__content" [innerHtml]="data.description"></p>
</div>
