import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FooterComponent } from '../footer/footer.component';
import { HeaderComponent } from '../header/header.component';
import { SideNavComponent } from '../side-nav/side-nav.component';
import { DialogService } from '../../../services/dialog.service';

@Component({
	selector: 'wyzeweb-layout',
	standalone: true,
	imports: [CommonModule, RouterModule, FooterComponent, HeaderComponent, SideNavComponent],
	templateUrl: './layout.component.html',
	styleUrl: './layout.component.scss'
})
export class LayoutComponent {
	dialogService = inject(DialogService);

	closeSubNavigation() {
		this.dialogService.closeSubNavigation();
	}
}
