import { AfterViewInit, Component, ElementRef, Inject, Input, PLATFORM_ID, Renderer2 } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { ICON, OptimizedImageComponent } from '@i-wyze/ui-shared-library';
import { environment } from 'apps/ng/src/environments/environment';

@Component({
	selector: 'wyzeweb-customer-feedback-page',
	standalone: true,
	imports: [CommonModule, OptimizedImageComponent],
	templateUrl: './customer-feedback-page.component.html',
	styleUrls: ['./customer-feedback-page.component.scss']
})
export class CustomerFeedbackPageComponent implements AfterViewInit {
	constructor(private renderer: Renderer2, private el: ElementRef, @Inject(PLATFORM_ID) private platformId: Object) {}

	protected readonly ICON = ICON;

	@Input() data: any;
	@Input() index!: number;

	ngAfterViewInit(): void {
		if (isPlatformBrowser(this.platformId)) {
			const link = this.renderer.createElement('link');
			link.rel = 'stylesheet';
			link.href = 'https://cdnjs.cloudflare.com/ajax/libs/jquery/3.4.1/jquery.min.js';
			this.renderer.appendChild(document.head, link);

			const script = this.renderer.createElement('script');
			script.type = 'text/javascript';
			script.async = true;
			script.id = 'code';
			script.setAttribute('data-api', '/api/hello-peter');
			script.setAttribute('data-appUrl', 'https://business.hellopeter.com');
			script.setAttribute('data-search', `${this.brand}`);
			script.setAttribute(
				'data-params',
				'{"minReviewsRequired":{"ratings":[3,4,5]},"widget_header_text":"What our customers say:","widget_background_color":"original","dimension":"700","font_color":"#58595b","five_star_rating_color":"#00bf87","average_star_rating_color":"#00bf87","four_star_rating_color":"#30c8a7","three_star_rating_color":"#30c8a7","isMobile":false,"previewBackground":"#2484b5","is_logo_disable":true,"previewIframeHeight":"450px","previewIframeWidth":"700px","num_of_reviews":10,"reviewers_name_as_anonymous":false,"display_replies":false,"showTagLine":false,"widget_header":"metrics","google_metrix":true,"google_reviews":true,"facebook_metrix":false,"facebook_reviews":false,"aboutLink":"#","logo_color":"logo_b2c.svg"}'
			);
			script.setAttribute('data-appFront', 'https://www.hellopeter.com');
			script.src = 'https://business.hellopeter.com/static/js/seoPage.js?v=2.1.2';
			this.renderer.appendChild(document.head, script);

			const modalDiv = this.renderer.createElement('div');
			modalDiv.className = 'hp-widget-modal';
			modalDiv.style.display = 'none';
			modalDiv.id = 'hp-modal';
			this.renderer.appendChild(this.el.nativeElement, modalDiv);

			const seoPageDiv = this.renderer.createElement('div');
			seoPageDiv.style.display = 'none';
			seoPageDiv.id = 'hp_seo_page';
			this.renderer.appendChild(this.el.nativeElement, seoPageDiv);

			const loaderImg = this.renderer.createElement('img');
			loaderImg.id = 'iframe-loader';
			loaderImg.src = 'https://business.hellopeter.com/static/img/loaders/ajax-loader.gif';
			this.renderer.appendChild(this.el.nativeElement, loaderImg);
		}
	}

	get brand() {
		return environment.brand;
	}
}
