import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicCardComponent } from '../../directives/dynamic-card-loader.directive';
import { InformationItemComponent } from '../information-item/information-item.component';
import { LooseTextComponent } from '../loose-text/loose-text.component';
import { UiSharedLibraryModule } from '@i-wyze/ui-shared-library';

@Component({
	selector: 'wyzeweb-column',
	standalone: true,
	imports: [CommonModule, DynamicCardComponent, InformationItemComponent, LooseTextComponent, UiSharedLibraryModule],
	templateUrl: './column.component.html',
	styleUrl: './column.component.scss'
})
export class ColumnComponent {
	@Input() data: any;
}
