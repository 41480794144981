<footer class="footer">
	<div class="d-flex footer__logo-container">
		<wyzeweb-optimized-image [source]="ICON.FooterLogo" height="163" width="19" alt="footer logo">
		</wyzeweb-optimized-image>
	</div>
	<div class="d-flex footer__header">
		<div class="d-flex-wrap footer__header-content">
			<div class="footer__column">
				<p class="footer__copyright font-small">
					Copyright {{ contentStore.brand()!.long_company_name }} &copy; {{ getCurrentYear() }}
				</p>
			</div>
			<nav>
				<ul class="d-flex-wrap footer__header-content-list">
					@for (item of contentStore.filterLinksByGroup(SITE_LINK.FOOTER_ADDITIONAL); track $index) {
					<li>
						<a [href]="item.link">{{ item.display_name }}</a>
					</li>
					}
				</ul>
			</nav>
		</div>
	</div>
	<div class="d-flex-wrap footer__main-content">
		<div class="footer__column">
			<wyzeweb-link-list
				[title]="contentStore.brand()!.long_company_name"
				[type]="LIST_TYPE.ROW"
				[links]="contentStore.filterLinksByGroup(SITE_LINK.FOOTER_COL_1_TOP)"></wyzeweb-link-list>
			<wyzeweb-link-list
				title="Products"
				[type]="LIST_TYPE.ROW"
				[links]="contentStore.filterLinksByGroup(SITE_LINK.FOOTER_COL_1_BOTTOM)"></wyzeweb-link-list>
		</div>
		<div class="footer__column">
			<wyzeweb-link-list
				[title]="'About ' + contentStore.brand()!.long_company_name"
				[type]="LIST_TYPE.COLUMN"
				[links]="contentStore.filterLinksByGroup(SITE_LINK.FOOTER_COL_2_TOP)"></wyzeweb-link-list>
			<p
				class="footer__disclaimer font-small font-small--light"
				[innerHTML]="contentStore.brand()!.disclaimer"></p>
		</div>
		<div class="footer__column">
			<wyzeweb-link-list
				[title]="'Download ' + contentStore.brand()!.short_company_name + ' App'"
				[type]="LIST_TYPE.COLUMN"
				[links]="contentStore.filterLinksByGroup(SITE_LINK.FOOTER_COL_3_TOP)"></wyzeweb-link-list>
			<wyzeweb-link-list
				title="Contact Us"
				[type]="LIST_TYPE.COLUMN"
				[links]="contentStore.filterLinksByGroup(SITE_LINK.FOOTER_COL_3_BOTTOM)"></wyzeweb-link-list>
		</div>
		<div class="footer__column">
			<wyzeweb-link-list
				title="Social Media"
				[type]="LIST_TYPE.COLUMN"
				[links]="contentStore.filterLinksByGroup(SITE_LINK.FOOTER_COL_4)"></wyzeweb-link-list>
		</div>
	</div>
</footer>
