import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ICON, UiSharedLibraryModule } from '@i-wyze/ui-shared-library';
import { ReviewsService } from '../../services/reviews.service';
import { Observable } from 'rxjs';

@Component({
	selector: 'wyzeweb-customer-feedback-badge',
	standalone: true,
	imports: [CommonModule, UiSharedLibraryModule],
	templateUrl: './customer-feedback-badge.component.html',
	styleUrl: './customer-feedback-badge.component.scss'
})
export class CustomerFeedbackBadgeComponent {
	reviewsService = inject(ReviewsService);
	protected readonly ICON = ICON;

	stars: number[] = Array(4).fill(0);
	icon = {
		Star: ICON.Star
	};

	reviewsData$: Observable<{ rating: number; reviews: number }> = this.reviewsService.loadHelloPeterScript();
}
