import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InformationItemComponent } from '../information-item/information-item.component';
import { DynamicCardComponent } from '../../directives/dynamic-card-loader.directive';

@Component({
	selector: 'wyzeweb-grid',
	standalone: true,
	imports: [CommonModule, InformationItemComponent, DynamicCardComponent],
	templateUrl: './grid.component.html',
	styleUrl: './grid.component.scss'
})
export class GridComponent {
	@Input() data: any;
	@Input() index!: number;

	public get shouldSpanEntireRow(): boolean {
		return this.data.content.items.length % 3 === 1;
	}

	public get gridClass(): string {
		return `grid grid--${this.data.learn_more_url ? 'double-column' : 'single-column'}`;
	}
}
