import { Component, Input, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BUTTON_TYPE, BasicCardComponent, ICON, OptimizedImageComponent } from '@i-wyze/ui-shared-library';
import { EventService } from '../../services/event.service';

@Component({
	selector: 'wyzeweb-pyramid',
	standalone: true,
	imports: [CommonModule, BasicCardComponent, OptimizedImageComponent],
	templateUrl: './pyramid.component.html',
	styleUrl: './pyramid.component.scss'
})
export class PyramidComponent {
	public readonly BUTTON_TYPE = BUTTON_TYPE;
	public readonly ICON = ICON;

	eventService = inject(EventService);

	@Input() data: any;

	public get trimmedItems() {
		return this.data.content.items.filter((item: any, index: number) => index > 0);
	}
}
