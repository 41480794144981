<p class="link-list__heading">{{ title }}</p>
<ul [class]="getListClasses">
	@for (link of links; track link.display_name) {
	<li [class]="getListItemClasses(link)">
		<a [href]="link.link" [class]="linkClass">
			@if (link.icon_name) {
			<wyzeweb-optimized-image
				[source]="getIconPath(link.icon_name)"
				height="14"
				width="14"
				[alt]="link.icon_name">
			</wyzeweb-optimized-image>
			}
			<span [innerHTML]="link.display_name"></span>
		</a>
	</li>
	}
</ul>
