import { Injectable, signal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject } from 'rxjs';
import { SideNavComponent } from '../components/page-furniture/side-nav/side-nav.component';
import { SiteLinkModel } from '../models/nucleus.model';

@Injectable({
	providedIn: 'root'
})
export class DialogService {
	private _sideNavOpen$ = new BehaviorSubject<boolean>(false);
	public sideNavOpen$ = this._sideNavOpen$.asObservable();

	activeLink = signal<SiteLinkModel | null>(null);

	constructor(private dialog: MatDialog) {
		this.sideNavOpen$.subscribe(open => {
			if (open) {
				this.dialog.open(SideNavComponent);
			} else {
				this.dialog.closeAll();
			}
		});
	}

	openSubNavigation(activeLink: SiteLinkModel): void {
		this.activeLink.set(activeLink);
	}

	closeSubNavigation(): void {
		this.activeLink.set(null);
	}

	openSideNav() {
		this._sideNavOpen$.next(true);
	}

	closeSideNav() {
		this._sideNavOpen$.next(false);
	}
}
