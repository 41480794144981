<div class="sub-menu__container" [class.multi-groups]="linkGroups.length > 1">
	<div class="product-sub-menus-container">
		@for (linkGroup of linkGroups; track $index) {
		<div class="column" style="flex: 1">
			<ng-container *ngTemplateOutlet="menuMainLinkOrSpaceHolder; context: { groupIndex: $index }"></ng-container>

			<div class="sub-menu">
				@if (linkGroups.length > 1) {
				<a class="sub-menu__link sub-menu__link--bold" [href]="linkGroup.groupMainLink.link">
					{{ linkGroup.groupMainLink.display_name }}
					<div class="reverse-image">
						<uis-optimized-image
							[source]="ICON.ArrowUpIcon"
							height="10"
							width="10"
							alt="down arrow"></uis-optimized-image>
					</div>
				</a>
				}

				<div class="sub-menu__nested-group">
					@for (subLink of linkGroup.subLinks; track $index) {
					<a class="sub-menu__link" [href]="subLink.link">{{ subLink.display_name }}</a>
					}
				</div>
			</div>
		</div>
		}
	</div>
</div>

<ng-template #menuMainLinkOrSpaceHolder let-groupIndex="groupIndex">
	@if (groupIndex === 0) {
	<a class="sub-menu__link sub-menu__link--main" [href]="link.link">
		{{ link.display_name }}

		@if (linkGroups.length > 1) {
		<div class="reverse-image">
			<uis-optimized-image
				[source]="ICON.ArrowUpIcon"
				height="10"
				width="10"
				alt="down arrow"></uis-optimized-image>
		</div>
		}
	</a>
	} @else {
	<div class="sub-menu__link sub-menu__link--main"></div>
	}
</ng-template>
