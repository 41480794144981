import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SectionComponent } from '../../shared/components/section/section.component';
import { ContentStore } from '../../shared/services/content.store';
import { MobileAccordionComponent } from '../../shared/components/section/mobile-accordion/mobile-accordion.component';
import { RecentPostsComponent } from '../../shared/components/recent-posts/recent-posts.component';
import { AppWindowService } from '../../shared/services/window.service';

@Component({
	selector: 'wyzeweb-landing',
	standalone: true,
	imports: [CommonModule, SectionComponent, MobileAccordionComponent, RecentPostsComponent],
	templateUrl: './landing-page.component.html',
	styleUrl: './landing-page.component.scss'
})
export class LandingPageComponent {
	public contentStore = inject(ContentStore);
	public windowService = inject(AppWindowService);
}
