import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InformationItemComponent } from '../information-item/information-item.component';
import { DynamicCardComponent } from '../../directives/dynamic-card-loader.directive';

@Component({
	selector: 'wyzeweb-cube',
	standalone: true,
	imports: [CommonModule, InformationItemComponent, DynamicCardComponent],
	templateUrl: './cube.component.html',
	styleUrl: './cube.component.scss'
})
export class CubeComponent {
	@Input() data: any;
}
