import { AfterViewInit, Component, EventEmitter, Output, PLATFORM_ID, ViewChild, inject } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { UiSharedLibraryModule } from '@i-wyze/ui-shared-library';

// TODO: consider moving this to the UI-Library -> but consider cost of having google-maps dependency there, e.g handling api keys

interface LocationMarker {
	lat: number | undefined;
	lng: number | undefined;
	formattedAddress: string | undefined;
}

@Component({
	selector: 'wyzeweb-google-autocomplete',
	standalone: true,
	imports: [CommonModule, UiSharedLibraryModule],
	templateUrl: './google-autocomplete.component.html',
	styleUrl: './google-autocomplete.component.scss'
})
export class GoogleAutocompleteComponent implements AfterViewInit {
	@ViewChild('searchField', { static: false }) searchField!: any;
	@Output() userLocationUpdated: EventEmitter<LocationMarker> = new EventEmitter<LocationMarker>();

	platformId = inject(PLATFORM_ID);
	placesService!: google.maps.places.Autocomplete;

	ngAfterViewInit() {
		if (!isPlatformBrowser(this.platformId)) return;
		this.loadAutocomplete();
	}

	loadAutocomplete() {
		const input = this.searchField.nativeElement as HTMLInputElement;
		google.maps
			.importLibrary('places')
			// @ts-expect-error because we have the wrong types
			.then(({ Autocomplete }) => {
				this.placesService = new Autocomplete(input, {
					types: ['geocode']
				});
				this.placesService?.addListener('place_changed', () =>
					this.handlePlaceSelected(this.placesService?.getPlace())
				);
			})
			.catch(error => console.log('Failed to load places library:', error));
	}

	handlePlaceSelected(place: google.maps.places.PlaceResult) {
		if (!place.geometry) return console.warn('No details available for input: ' + place.name);
		return this.userLocationUpdated.emit({
			lat: place.geometry.location?.lat(),
			lng: place.geometry.location?.lng(),
			formattedAddress: place.formatted_address
		});
	}
}
