<div class="recent-posts">
	<uis-tab (tabSelected)="handleTabClicked($event)" [tabs]="tabs"></uis-tab>
	<div class="recent-posts__list">
		<p><b>Recent Posts</b></p>
		<ul>
			@for (post of contentStore.recentPosts(); track $index) {
			<li>
				<a [href]="post.full_path"
					><b>{{ post.blog_heading }}</b></a
				><br />
			</li>
			<p class="publish-date">{{ post.publish_date | date }}</p>
			}
		</ul>
	</div>
</div>
