import { Component, Input, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiSharedLibraryModule } from '@i-wyze/ui-shared-library';
import { BannerEvent } from '@i-wyze/ui-shared-library/lib/content-items/interfaces/banner.interfaces';
import { EventService } from '../../services/event.service';

@Component({
	selector: 'wyzeweb-banner-slider',
	standalone: true,
	imports: [CommonModule, UiSharedLibraryModule],
	templateUrl: './banner-slider.component.html',
	styleUrl: './banner-slider.component.scss'
})
export class BannerSliderComponent {
	@Input() data: any;

	eventService = inject(EventService);

	handleBannerEvent(event: BannerEvent): void {
		this.eventService.handleEvent(event);
	}
}
