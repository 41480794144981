<div class="map-container">
	<google-map #map id="map" [options]="mapOptions" width="100%" height="100%">
		@if (destinationResults()) {
		<map-directions-renderer [directions]="destinationResults()" [options]="{ suppressMarkers: true }">
		</map-directions-renderer>
		} @if(userMarker) {
		<map-circle [options]="userMarker?.circleOptions" [center]="userMarker" />
		<map-marker #marker="mapMarker" [position]="userMarker?.markerOptions" [options]="userMarker?.markerOptions" />
		} @if(nearestMarkers){ @for (marker of nearestMarkers; track $index ) {
		<map-circle [options]="marker.circleOptions" [center]="marker" />
		<map-marker
			#marker="mapMarker"
			[position]="marker.markerOptions"
			[options]="marker.markerOptions"
			(mapClick)="onMapClick(marker.markerOptions)" />
		} }
	</google-map>
</div>
