export enum EVENT_TYPE {
	START_QUOTE = 'startQuote',
	PRODUCT_LEARN_MORE = 'productLearnMore',
	LEARN_MORE = 'learnMore',
	CALL_BACK = 'callBack',
	FILTER_SECTIONS = 'filterSection',
	DOWNLOAD = 'download',
	VIEW = 'view',
	EMAIL = 'email',
	DIAL = 'dial',
	SCROLL = 'scroll',
	LOGIN = 'login',
	MAGICAL_MOMENTS_LEARN_MORE = 'magicalMomentsLearnMore'
}

export enum DEPARTMENT {
	CLAIMS = 'CL',
	QUOTES = 'QT',
	CUSTOMER_CARE = 'CC',
	COMPLAINTS = 'CP'
}
