import { Component, AfterViewInit, Renderer2, ElementRef, Inject, PLATFORM_ID } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { environment } from 'apps/ng/src/environments/environment';

@Component({
	selector: 'wyzeweb-customer-feedback-badge',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './customer-feedback-badge.component.html',
	styleUrls: ['./customer-feedback-badge.component.scss']
})
export class CustomerFeedbackBadgeComponent implements AfterViewInit {
	constructor(private renderer: Renderer2, private el: ElementRef, @Inject(PLATFORM_ID) private platformId: Object) {}

	ngAfterViewInit(): void {
		if (isPlatformBrowser(this.platformId)) {
			setTimeout(() => {
				const widgetElement = document.getElementById('hp-widget-verified_ribbon_badge');
				if (widgetElement) {
					this.renderer.setStyle(widgetElement, 'display', 'block');
					const widgetContainer = this.el.nativeElement.querySelector('#hp_profile_link');
					if (widgetContainer) {
						this.renderer.appendChild(widgetContainer, widgetElement);
					}
				}
			}, 500);
			const script = this.renderer.createElement('script');
			script.type = 'text/javascript';
			script.async = true;
			script.id = 'code-rb';
			script.setAttribute('data-api', '/api/hello-peter');
			script.setAttribute('data-appUrl', 'https://business.hellopeter.com');
			script.setAttribute('data-search-rb', `${this.brand}`);
			script.setAttribute(
				'data-params-rb',
				'{"widget_background_color":"original","font_color":"#58595b","logo_color":"logo_b2c.svg","average_star_rating_color":"#00bf87","isMobile":false,"previewBackground":"transparent","is_logo_disable":true,"previewIframeHeight":"144px","previewIframeWidth":"519px","showTagLine":false,"aboutLink":"https://intercom.help/hellopeter/en/articles/4529352-ribbon-badge-widget "}'
			);
			script.setAttribute('data-appFront', 'https://www.hellopeter.com');
			script.src = 'https://business.hellopeter.com/static/js/ribbonBadge.js?v=2.1';
			this.renderer.appendChild(document.body, script);
		}
	}
	get brand() {
		return environment.brand;
	}
}
