import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FONT_MODIFIER, LinkListComponent } from '../page-furniture/footer/link-list/link-list.component';
import { LIST_TYPE } from '../../enums/footer.enums';
import { ContentStore } from '../../services/content.store';
import { SITE_LINK } from '../../enums/site-link.enums';

@Component({
	selector: 'wyzeweb-social-list',
	standalone: true,
	imports: [CommonModule, LinkListComponent],
	templateUrl: './social-list.component.html',
	styleUrl: './social-list.component.scss'
})
export class SocialListComponent {
	public readonly LIST_TYPE = LIST_TYPE;
	public readonly SITE_LINK = SITE_LINK;
	public readonly FONT_MODIFIER = FONT_MODIFIER;

	contentStore = inject(ContentStore);
}
