import { Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinkListComponent } from './link-list/link-list.component';
import { LIST_TYPE } from '../../../enums/footer.enums';
import { OptimizedImageComponent } from '../../optimized-image/optimized-image.component';
import { ContentStore } from '../../../services/content.store';
import { SITE_LINK } from '../../../enums/site-link.enums';
import { ICON } from '@i-wyze/ui-shared-library';

@Component({
	selector: 'wyzeweb-footer',
	standalone: true,
	imports: [CommonModule, LinkListComponent, OptimizedImageComponent],
	templateUrl: './footer.component.html',
	styleUrl: './footer.component.scss'
})
export class FooterComponent {
	public readonly LIST_TYPE = LIST_TYPE;
	public readonly ICON = ICON;
	public readonly SITE_LINK = SITE_LINK;

	contentStore = inject(ContentStore);

	public getCurrentYear(): number {
		return new Date().getFullYear();
	}
}
