import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from 'apps/ng/src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ReviewsService {
	http = inject(HttpClient);
	loadHelloPeterScript(): Observable<any> {
		return this.http.get<any>(`${environment.apiUrl}/reviews/get-ribbon-badge`);
	}
}
