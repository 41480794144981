<div class="side-nav-wrapper">
	<div class="overlay" (click)="dialogService.closeSideNav()"></div>
	@if (!isSearchOpen()) {
	<div class="side-nav">
		<wyzeweb-optimized-image
			class="side-nav__close"
			[source]="ICON.CloseIcon"
			height="24"
			width="24"
			alt="icon close side navigation menu"
			(click)="dialogService.closeSideNav()">
		</wyzeweb-optimized-image>
		<wyzeweb-nav-list
			(subMenuActivated)="setCurrentConfig($event)"
			[listConfig]="listConfig()"
			[currentRoute]="routeService.currentRoute()"></wyzeweb-nav-list>
		<wyzeweb-optimized-image
			(click)="openSearch()"
			class="side-nav__search-icon"
			[source]="ICON.SearchIcon"
			height="20"
			width="20"
			alt="search icon">
		</wyzeweb-optimized-image>
	</div>
	}@else {
	<div class="side-nav">
		<div class="side-nav__search-container">
			<uis-search
				(resetSearch)="contentStore.pageSearchResults.set([])"
				(searchTermEntered)="handleSearchEvent($event)"
				[showSearch]="true"
				[options]="contentStore.pageSearchResults()"
				[type]="SEARCH_TYPE.TRANSPARENT"></uis-search>
		</div>
	</div>
	}
</div>
