import { DestroyRef, Injectable, PLATFORM_ID, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { SessionStorageService } from './session.service';
import { SessionStorageKey } from '../enums/session-storage-key.enum';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
	providedIn: 'root'
})
export class RouteService {
	router = inject(Router);
	destroyRef = inject(DestroyRef);
	route = inject(ActivatedRoute);
	platformId = inject(PLATFORM_ID);
	sessionService = inject(SessionStorageService);
	currentRoute = signal<string>('');

	constructor() {
		this.router.events.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(event => {
			if (event instanceof NavigationEnd) {
				if (
					isPlatformBrowser(this.platformId) &&
					this.sessionService.getItem(SessionStorageKey.ORIGIN) === null
				)
					this.sessionService.setItem(SessionStorageKey.ORIGIN, this.getDomain(document.referrer));
				this.currentRoute.set(event.urlAfterRedirects);
			}
		});
		this.route.queryParams.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(params => {
			const queryParamValue = this.getUcidFromUrlParams(params);
			if (queryParamValue) {
				this.sessionService.setItem(SessionStorageKey.UCID, queryParamValue);
			}
		});
	}

	public getMappedRoute(): string {
		const segments = this.currentRoute().split('?')[0].split('/');
		let lastSegment = segments[segments.length - 1]?.split('#')[0];
		if (lastSegment === 'post') {
			lastSegment = 'blog';
		}
		return this.currentRoute() === '/' || !lastSegment ? 'landing' : lastSegment;
	}

	private getUcidFromUrlParams(urlQueryParams: Params): string {
		return urlQueryParams['ucid'] ?? urlQueryParams['UCID'] ?? urlQueryParams['source'];
	}

	private getDomain(url: string) {
		if (url) {
			const regex = /(?:https?:\/\/)?(?:www\.)?([^\/]+)/;
			const match = url.match(regex);
			const domain = match ? match[1] : '';

			const allowedReferrers = ['google.com', 'google.co.za', 'bing.com'];
			if (domain && allowedReferrers.includes(domain)) {
				return domain;
			} else {
				return '';
			}
		} else {
			return '';
		}
	}
}
