import { Injectable } from '@angular/core';
import { EventToDataProviderMap, GoogleTagManagerDataProvider } from '@i-wyze/ui-shared-library';
import { SessionStorageService } from './session.service';
import { SessionStorageKey } from '../enums/session-storage-key.enum';
import { environment } from '../../../environments/environment';

@Injectable()
export class GtmDataProvider extends GoogleTagManagerDataProvider {
	// Any custom data that needs to be added to specific events should be added here
	protected readonly EVENT_EXTRA_DATA_PROVIDERS: EventToDataProviderMap = {};

	constructor(private sessionService: SessionStorageService) {
		super();
	}

	getUcid(): string | undefined {
		return this.sessionService.getItem(SessionStorageKey.UCID) ?? undefined;
	}

	getCompanyCode(): string | undefined {
		return environment.brand;
	}

	getPhoneNumber(): string | undefined {
		return undefined;
	}

	getProduct(): { productCode: string | undefined; subProduct?: string | undefined } {
		return { productCode: undefined, subProduct: undefined };
	}

	getGlobalExtraData(): null {
		// Any other data that needs to be added to all GTM events should be returned here
		return null;
	}
}
