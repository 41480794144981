import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LIST_TYPE } from '../../../../enums/footer.enums';
import { OptimizedImageComponent } from '../../../optimized-image/optimized-image.component';
import { SiteLinkModel } from '../../../../models/nucleus.model';
import { ICON } from '@i-wyze/ui-shared-library';

export enum FONT_MODIFIER {
	SMALL_LIGHT = 'font-small--light',
	BASE = 'font-tertiary'
}

@Component({
	selector: 'wyzeweb-link-list',
	standalone: true,
	imports: [CommonModule, OptimizedImageComponent],
	templateUrl: './link-list.component.html',
	styleUrl: './link-list.component.scss'
})
export class LinkListComponent {
	@Input() links: SiteLinkModel[] | null = [];
	@Input() title = '';
	@Input() type: LIST_TYPE = LIST_TYPE.ROW;
	@Input() fontModifier: FONT_MODIFIER = FONT_MODIFIER.SMALL_LIGHT;

	public get getListClasses(): string {
		return `d-flex link-list__list link-list__list--${this.type}`;
	}

	public getListItemClasses(link: SiteLinkModel): string {
		return `d-flex link-list__list-item ${
			this.type === LIST_TYPE.ROW && link.has_border_decoration ? 'link-list__list-item--border' : ''
		}`;
	}

	public get linkClass(): string {
		return `${this.fontModifier} flex-center`;
	}

	//? to fix typescript errors
	public getIconPath(name: string): string {
		return ICON[name as keyof typeof ICON];
	}
}
