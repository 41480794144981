@if(eventService.isLoading()){
<uis-optimized-image [source]="ICON.LoaderIcon" height="100" width="100" alt="loading indicator"> </uis-optimized-image>
} @else if(!eventService.isLoading() && !eventService.wasLeadSubmissionSuccessful()) {
<form class="contact-form">
	<div class="contact-form__input-container">
		<uis-text-input
			[shouldDisplayErrors]="hasSubmitted()"
			[formInput]="getFormControl('fullName')"
			title="Name and Surname"
			helperText="How can we address you?"></uis-text-input>
		<uis-text-input
			[shouldDisplayErrors]="hasSubmitted()"
			[formInput]="getFormControl('cellphoneNumber')"
			title="Cellphone number"></uis-text-input>

		<label class="contact-form__input-label"><b>Who</b> must phone you back?</label>
		<uis-basic-selector
			[shouldDisplayErrors]="hasSubmitted()"
			[formInput]="getFormControl('department')"
			[options]="departmentOptions"></uis-basic-selector>

		<label class="contact-form__input-label">In connection with <b>which product?</b></label>
		<uis-basic-selector
			[shouldDisplayErrors]="hasSubmitted()"
			[formInput]="getFormControl('product')"
			[options]="productOptions"></uis-basic-selector>
		<div>
			<label class="contact-form__input-label">So, <b>how</b> can we help you?</label>
			<uis-text-input
				[shouldDisplayErrors]="hasSubmitted()"
				[formInput]="getFormControl('message')"
				title="Message"></uis-text-input>
		</div>
		<div class="contact-form__btn-container font-tertiary">
			<uis-icon-button (clicked)="onSubmit()" [appendIconSrc]="ICON.ArrowWhite">Submit</uis-icon-button>
			We're standing by to assist you with feedback, guidance and support.
		</div>
	</div>
</form>
} @else {
<div class="success">
	<uis-optimized-image [source]="ICON.SuccessSent" height="100" width="100" alt="success indicator">
	</uis-optimized-image>
	<p><b>Thank you for contacting us</b></p>
	<p>An iWYZE consultant will get back to you shortly.</p>
</div>
}
