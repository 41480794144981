<div [class]="gridClass">
	@for (item of data.content.items; track $index) {
	<div class="grid__item" [class.grid__item--full-span]="shouldSpanEntireRow">
		<!-- TODO: will be made dynamic in another ticket -->
		@if(item.component_type === 'CARD'){
		<wyzeweb-dynamic-card-loader [data]="item"></wyzeweb-dynamic-card-loader>
		} @else {
		<wyzeweb-information-item ngSkipHydration [data]="item"></wyzeweb-information-item>
		}
	</div>
	}
</div>
