<div class="pyramid">
	<div class="pyramid__image">
		<uis-optimized-image
			[source]="data.content.items[0]?.desktop_image"
			[height]="data.content.items[0]?.height"
			[width]="data.content.items[0]?.width"
			[priority]="true">
		</uis-optimized-image>
	</div>
	<div class="pyramid__content">
		<!-- TODO: replace with dynamic component -->
		@for (card of trimmedItems; track $index){
		<div class="pyramid__item">
			<uis-basic-card (cardButtonClicked)="eventService.handleEvent($event)" [card]="card"></uis-basic-card>
		</div>
		}
	</div>
</div>
