@if (eventService.isLoading() && this.isSignIn()) {
<uis-optimized-image
	class="top-loader"
	[source]="ICON.LoaderIcon"
	height="100"
	width="100"
	alt="loading indicator"></uis-optimized-image>
} @if(data.content.items[0].has_feefo){
<wyzeweb-customer-feedback-badge></wyzeweb-customer-feedback-badge>
} @if(data.content.items[0]) { @switch(data.content.items[0].banner_type){ @case('BASIC'){
<uis-basic-banner [banner]="data.content.items[0]" (buttonClick)="handleButtonClick($event)"></uis-basic-banner>
} @case('LEAD'){
<uis-lead-capture-banner
	[wasSubmissionSuccessful]="eventService.wasLeadSubmissionSuccessful()"
	(formSubmitted)="handleFormSubmission($event)"
	(closeClicked)="handleClose()"
	[banner]="data.content.items[0]"
	[inputConfig]="inputConfig"></uis-lead-capture-banner>
} @case('SEARCH'){
<uis-search-banner
	(tabSelected)="eventService.handleEvent($event)"
	(searchEvent)="handleSearchEvent($event)"
	[banner]="data.content.items[0]"></uis-search-banner>
} } }
