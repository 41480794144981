import { Component, HostListener, Input, OnInit, PLATFORM_ID, inject, signal } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
	UiSharedLibraryModule,
	LeadCaptureBannerComponent,
	BasicBannerComponent,
	SearchBannerComponent,
	ICON
} from '@i-wyze/ui-shared-library';
import { EventService } from '../../services/event.service';
import { configMap } from './input-config';
import { InputConfig } from '@i-wyze/ui-shared-library/lib/forms/forms';
import { CustomerFeedbackBadgeComponent } from '../customer-feedback-badge/customer-feedback-badge.component';
import { DEPARTMENT, EVENT_TYPE } from '../../enums/event.enums';
import { ContentStore } from '../../services/content.store';
import { SessionStorageService } from '../../services/session.service';
import { SessionStorageKey } from '../../enums/session-storage-key.enum';

@Component({
	selector: 'wyzeweb-banner',
	standalone: true,
	imports: [
		CommonModule,
		UiSharedLibraryModule,
		LeadCaptureBannerComponent,
		BasicBannerComponent,
		SearchBannerComponent,
		CustomerFeedbackBadgeComponent
	],
	templateUrl: './banner.component.html',
	styleUrl: './banner.component.scss'
})
export class BannerComponent implements OnInit {
	public readonly ICON = ICON;

	@Input() data: any;
	@Input() index!: number;

	eventService = inject(EventService);
	platformId = inject(PLATFORM_ID);
	contentStore = inject(ContentStore);
	sessionService = inject(SessionStorageService);

	inputConfig!: { [key: string]: InputConfig[] };

	hasRunFilters = signal<boolean>(false);

	ngOnInit(): void {
		this.inputConfig = configMap[this.data.content.items[0]?.lead_capture?.type] || null;
		this.onResize();
	}

	@HostListener('window:resize', ['$event'])
	onResize() {
		if (isPlatformBrowser(this.platformId)) {
			if (this.data.content.items[0]?.tabs.length > 0 && window.innerWidth > 425 && !this.hasRunFilters()) {
				this.eventService.handleEvent({
					event: EVENT_TYPE.FILTER_SECTIONS,
					metadata: { value: this.contentStore.currentFilter() || 'all' }
				});
				this.hasRunFilters.set(true);
			}
		}
	}

	handleFormSubmission(event: any): void {
		if (!event.metadata || !event.metadata.product_code)
			event.metadata = { ...event.metadata, ...this.data.content.items[0].product };
		event.metadata.origin = this.sessionService.getItem(SessionStorageKey.ORIGIN);
		if (event.metadata.idNumber && !event.metadata.department) event.metadata.department = DEPARTMENT.CLAIMS;
		this.eventService.handleEvent(event);
	}

	handleButtonClick(event: any) {
		console.log(event);
	}

	handleClose() {
		this.eventService.wasLeadSubmissionSuccessful.set(false);
	}

	handleSearchEvent(event: any) {
		this.contentStore.getCards(event);
	}
}
