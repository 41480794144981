<header class="header" #header>
	<div class="header__top-content flex-center color-netural-900" #topContent>
		<p class="font-weight-light" [innerHTML]="contentStore.brand().top_banner_content"></p>
	</div>
	<div class="header__bottom-content d-flex">
		<!-- TODO: add responsive functionality onto image component-- -->
		<div class="header__logo-container" (click)="navigateHome()">
			<div class="header__image">
				<uis-optimized-image [source]="ICON.HeaderLogo" height="56" width="60" alt="header logo">
				</uis-optimized-image>
			</div>
			<div class="header__image--mobile">
				<uis-optimized-image [source]="ICON.HeaderLogo" height="48" width="46" alt="header logo">
				</uis-optimized-image>
			</div>
		</div>
		<div class="header__image" (click)="navigateHome()">
			<uis-optimized-image
				[source]="ICON.HeaderCompanyNameLogo"
				height="74"
				width="171"
				alt="header company name logo"
				[priority]="true">
			</uis-optimized-image>
		</div>
		<div class="header__image--mobile" (click)="navigateHome()">
			<uis-optimized-image
				[source]="ICON.HeaderCompanyNameLogo"
				height="42"
				width="102"
				alt="header company name logo"
				[priority]="true">
			</uis-optimized-image>
		</div>
		<nav class="header__nav">
			<ul class="header__nav-list d-flex">
				@for (link of contentStore.filterLinksByGroup(SITE_LINK.HEADER); track $index) {
				<li class="header__nav-item">
					@if (link.has_subLinks) {
					<span
						(click)="setActiveLink(link)"
						class="d-flex header__nav-item-link"
						[class]="navLinkClasses(link, routeService.currentRoute())">
						{{ link.display_name }}
						<uis-optimized-image [source]="ICON.TriangleIcon" height="6" width="6" alt="sub menu icon">
						</uis-optimized-image>
					</span>
					} @else {
					<a
						class="d-flex header__nav-item-link"
						[href]="link.link"
						[class]="navLinkClasses(link, routeService.currentRoute())">
						{{ link.display_name }}
					</a>
					}
				</li>
				}
			</ul>
		</nav>
		<div class="header__actions d-flex">
			<uis-search
				class="header__search d-desktop"
				(resetSearch)="handleSearchReset()"
				(searchTermEntered)="handleSearch($event)"
				[options]="contentStore.pageSearchResults()"
				[type]="SEARCH_TYPE.TRANSPARENT"></uis-search>
			<uis-icon-button
				(clicked)="eventService.handleEvent({ event: 'login' })"
				[type]="BUTTON_TYPE.SECONDARY_SMALL"
				[appendIconSrc]="ICON.SigninIcon">
				Login
			</uis-icon-button>
			<uis-optimized-image
				class="header__menu-icon"
				[source]="ICON.HamburgerIcon"
				height="25"
				width="25"
				alt="hamburger menu icon"
				(click)="dialogService.openSideNav()">
			</uis-optimized-image>
		</div>
	</div>
	@if (dialogService.activeLink()) {
	<wyzeweb-sub-menu [link]="dialogService.activeLink()!"></wyzeweb-sub-menu>
	}
</header>
