export enum SITE_LINK {
	HEADER = 'header',
	FOOTER_COL_1_TOP = 'footer_col_1_top',
	FOOTER_COL_1_BOTTOM = 'footer_col_1_bottom',
	FOOTER_COL_2_TOP = 'footer_col_2_top',
	FOOTER_COL_3_TOP = 'footer_col_3_top',
	FOOTER_COL_3_BOTTOM = 'footer_col_3_bottom',
	FOOTER_COL_4 = 'footer_col_4',
	FOOTER_ADDITIONAL = 'footer_additional',
	PRODUCTS = 'products',
	SIDE_NAV_MAIN = 'side_nav_main',
	CONTACT = 'contact_us'
}
