import { Component, OnInit, inject, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouteService } from '../../../services/route.service';
import { NavListComponent } from './nav-list/nav-list.component';
import { DialogService } from '../../../services/dialog.service';
import { ContentStore } from '../../../services/content.store';
import { SITE_LINK } from '../../../enums/site-link.enums';
import { GroupConfig, ListConfig } from '../../../interfaces/side-nav.interfaces';
import { OptimizedImageComponent } from '../../optimized-image/optimized-image.component';
import { ICON, SEARCH_TYPE, SearchComponent } from '@i-wyze/ui-shared-library';

@Component({
	selector: 'wyzeweb-side-nav',
	standalone: true,
	imports: [CommonModule, NavListComponent, OptimizedImageComponent, SearchComponent],
	templateUrl: './side-nav.component.html',
	styleUrl: './side-nav.component.scss'
})
export class SideNavComponent implements OnInit {
	public readonly ICON = ICON;
	public readonly SEARCH_TYPE = SEARCH_TYPE;

	routeService = inject(RouteService);
	contentStore = inject(ContentStore);
	dialogService = inject(DialogService);

	listConfig = signal<ListConfig>({ title: '', links: [] });
	isSearchOpen = signal<boolean>(false);

	ngOnInit(): void {
		this.setCurrentConfig({ title: '', newGroup: '' });
	}

	setCurrentConfig(config: GroupConfig): void {
		if (!config.title) {
			this.listConfig.set({ title: '', links: this.contentStore.filterLinksByGroup(SITE_LINK.SIDE_NAV_MAIN) });
			return;
		}
		this.listConfig.set({ title: config.title, links: this.contentStore.filterLinksByGroup(config.newGroup) });
	}

	openSearch(): void {
		this.isSearchOpen.set(true);
	}

	handleSearchEvent(event: string): void {
		this.contentStore.getPagesBySearchTerm(event);
	}
}
