import { HttpClient } from '@angular/common/http';
import { Injectable, inject, signal } from '@angular/core';
import { environment } from 'apps/ng/src/environments/environment';

export enum SERVICE_TYPE {
	HIV = 'HIV',
	AUTOBOYS = 'AUTOBOYS'
}

@Injectable({
	providedIn: 'root'
})
export class LocationStoreService {
	http = inject(HttpClient);

	vehicleTestingLocations = signal<any[]>([]);
	HIVTestingLocations = signal<any[]>([]);

	loadLocations(): void {
		this.http.get<any>(`${environment.apiUrl}/service-location`).subscribe(locations => {
			this.vehicleTestingLocations.set(locations[SERVICE_TYPE.AUTOBOYS]);
			this.HIVTestingLocations.set(locations[SERVICE_TYPE.HIV]);
		});
	}
}
