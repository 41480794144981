import { Component, Input, inject } from '@angular/core';
import { ICON, PaginatorComponent, UiSharedLibraryModule } from '@i-wyze/ui-shared-library';
import { ContentStore } from '../../services/content.store';
import { CommonModule } from '@angular/common';
import { DynamicCardComponent } from '../../directives/dynamic-card-loader.directive';
import { Card } from '@i-wyze/ui-shared-library/lib/cards/interfaces/card.interfaces';
import { AppWindowService } from '../../services/window.service';

@Component({
	selector: 'wyzeweb-entries',
	standalone: true,
	imports: [CommonModule, PaginatorComponent, DynamicCardComponent, UiSharedLibraryModule],
	templateUrl: './entries.component.html',
	styleUrls: ['./entries.component.scss']
})
export class EntriesComponent {
	public readonly ICON = ICON;

	@Input() data: any;
	@Input() index!: number;
	@Input() sectionOrder!: number;

	contentStore = inject(ContentStore);
	windowService = inject(AppWindowService);

	options = [
		{ title: '1', value: 1 },
		{ title: '2', value: 2 },
		{ title: '3', value: 3 },
		{ title: '4', value: 4 },
		{ title: '5', value: 5 },
		{ title: '6', value: 6 },
		{ title: '7', value: 7 },
		{ title: '8', value: 8 },
		{ title: '9', value: 9 },
		{ title: '10', value: 10 }
	];

	get getCurrentDataset(): any {
		if (this.contentStore.cards().length > 0 && this.index === 1) return this.contentStore.cards();
		return this.windowService.isInCompactMode()
			? this.data.content.items
			: this.contentStore.page().sections[this.index].content.items;
	}

	updatePagination(event: any): void {
		this.contentStore.paginateSection(
			this.windowService.isInCompactMode() ? this.data.order : this.index,
			Number(event.page),
			Number(event.entries)
		);
	}

	getCardContainerClass(firstCard: Card): string {
		if (!firstCard) return 'paginator-grid';
		return firstCard.card_type === 'BASIC' || firstCard.card_type === 'DOCUMENT'
			? 'paginator-grid'
			: 'paginator-column';
	}

	handleSearchEvent(event: string): void {
		this.contentStore.getPagesBySearchTerm(event);
	}
	handleSearchReset(): void {
		this.contentStore.pageSearchResults.set([]);
	}
}
