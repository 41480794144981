<wyzeweb-section [data]="contentStore.page().sections[0]"></wyzeweb-section>
@for (section of groupedSections; track $index) { @if (section) {
<section class="section-closed">
	<div class="section-closed__content">
		<div class="section-closed__content-container">
			<h3 [class]="getTitleClass(section.variant, section.name)">{{ section.name }}</h3>
		</div>
		<div class="section-closed__content-container">
			@if (!isPanelOpen(section.name)){
			<span (click)="addOpenPanel(section.name)">+</span>
			} @else {
			<span (click)="removeOpenPanel(section.name)">-</span>
			}
		</div>
	</div>
</section>
@if (isPanelOpen(section.name)) { @for (section of section.sections; track $index) {
<wyzeweb-section [data]="appendCardsToSection(section)" [index]="$index"></wyzeweb-section>
} } } }
