import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiSharedLibraryModule } from '@i-wyze/ui-shared-library';

@Component({
	selector: 'wyzeweb-testimonial-slider',
	standalone: true,
	imports: [CommonModule, UiSharedLibraryModule],
	templateUrl: './testimonial-slider.component.html',
	styleUrl: './testimonial-slider.component.scss'
})
export class TestimonialSliderComponent {
	@Input() data: any;
	@Input() index!: number;
}
