import { FormValidators } from '@i-wyze/ui-shared-library';

export const allProducts = {
	primary: [
		{
			inputName: 'fullName',
			label: 'Name and surname',
			validators: [FormValidators.required('name')],
			type: 'TEXT'
		},
		{
			inputName: 'cellphoneNumber',
			label: 'Cellphone number',
			validators: [FormValidators.required('cellphone number'), FormValidators.cellphoneNumber()],
			type: 'TEXT'
		}
	],
	nested: [
		{
			inputName: 'nestedSelection',
			label: '',
			validators: [FormValidators.required('selection')],
			type: 'RADIO'
		}
	]
};
export const singleProducts = {
	primary: [
		{
			inputName: 'fullName',
			label: 'Name and surname',
			validators: [FormValidators.required('name')],
			type: 'TEXT'
		},
		{
			inputName: 'cellphoneNumber',
			label: 'Cellphone number',
			validators: [FormValidators.required('cellphone number'), FormValidators.cellphoneNumber()],
			type: 'TEXT'
		}
	]
};

export const claims = {
	primary: [
		{
			inputName: 'fullName',
			label: 'Name and surname',
			validators: [FormValidators.required('name')],
			type: 'TEXT'
		},
		{
			inputName: 'cellphoneNumber',
			label: 'Cellphone number',
			validators: [FormValidators.required('cellphone number'), FormValidators.cellphoneNumber()],
			type: 'TEXT'
		}
	],
	nested: [
		{
			inputName: 'nestedSelection',
			label: '',
			validators: [FormValidators.required('selection')],
			type: 'RADIO'
		},
		{
			inputName: 'idNumber',
			label: 'ID number',
			validators: [
				FormValidators.required('id number'),
				FormValidators.minimumLength(13, 'id number'),
				FormValidators.maximumLength(13, 'id number')
			],
			type: 'TEXT'
		},
		{
			inputName: 'emailAddress',
			label: 'Email address',
			validators: [FormValidators.required('email')],
			type: 'TEXT'
		}
	]
};

export const configMap: { [key: string]: any } = {
	ALL_PRODUCTS: allProducts,
	SINGLE_PRODUCT: singleProducts,
	CLAIMS: claims
};
