import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild,
	inject,
	signal
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { mapStyles } from '../map-style.data';
import { GoogleMap, MapCircle, MapDirectionsRenderer, MapDirectionsService, MapMarker } from '@angular/google-maps';
import { firstValueFrom } from 'rxjs';

declare global {
	interface Window {
		initMap: () => void;
	}
}

@Component({
	selector: 'wyzeweb-google-map',
	standalone: true,
	imports: [CommonModule, GoogleMap, MapCircle, MapMarker, MapDirectionsRenderer],
	providers: [MapDirectionsService],
	templateUrl: './google-map.component.html',
	styleUrl: './google-map.component.scss'
})
export class GoogleMapComponent implements OnChanges, OnInit {
	@Input() userMarker: any;
	@Input() destinationMarker: any;
	@Input() nearestMarkers: any = [];
	@Output() destinationLocationUpdated: EventEmitter<any> = new EventEmitter<any>();
	@Output() routeUpdated: EventEmitter<any> = new EventEmitter<any>();
	@ViewChild(GoogleMap, { static: false }) map: any;

	mapDirectionsService = inject(MapDirectionsService);
	changeDetection = inject(ChangeDetectorRef);

	mapOptions: google.maps.MapOptions = {
		center: { lat: 40, lng: -20 },
		zoom: 12,
		styles: mapStyles
	};

	destinationResults = signal<any>([]);

	ngOnInit(): void {
		setTimeout(() => this.changeDetection.detectChanges(), 5000);
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (!google.maps || !this.destinationMarker || !this.userMarker || this.nearestMarkers.length < 1) return;
		this.calculateAndDisplayRoute();
	}

	async calculateAndDisplayRoute() {
		const request: google.maps.DirectionsRequest = {
			destination: this.destinationMarker,
			origin: this.userMarker,
			travelMode: google.maps.TravelMode.DRIVING
		};

		try {
			const response = await firstValueFrom(this.mapDirectionsService.route(request));
			if (response.status === 'OK' && response.result) {
				this.destinationResults.set(response.result);
				this.routeUpdated.emit(response.result);
				this.changeDetection.detectChanges();
			} else {
				console.error('Directions request failed:', response.status);
			}
		} catch (error) {
			console.error('Error in calculating route:', error);
		}
	}

	onMapClick(event: any): void {
		this.destinationLocationUpdated.emit(event);
	}
}
