import {
	Component,
	DestroyRef,
	ElementRef,
	HostListener,
	OnInit,
	Renderer2,
	ViewChild,
	inject,
	signal
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogService } from '../../../services/dialog.service';
import { BUTTON_TYPE, ICON, SEARCH_TYPE, UiSharedLibraryModule } from '@i-wyze/ui-shared-library';
import { ContentStore } from '../../../services/content.store';
import { RouteService } from '../../../services/route.service';
import { SITE_LINK } from '../../../enums/site-link.enums';
import { SiteLinkModel } from '../../../models/nucleus.model';
import { SubMenuComponent } from './sub-menu/sub-menu.component';
import { EventService } from '../../../services/event.service';
import { EVENT_TYPE } from '../../../enums/event.enums';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
	selector: 'wyzeweb-header',
	standalone: true,
	imports: [CommonModule, UiSharedLibraryModule, SubMenuComponent],
	templateUrl: './header.component.html',
	styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit {
	public readonly ICON = ICON;
	protected readonly BUTTON_TYPE = BUTTON_TYPE;
	public readonly SITE_LINK = SITE_LINK;
	public readonly SEARCH_TYPE = SEARCH_TYPE;

	@ViewChild('header', { static: true }) header!: ElementRef;
	@ViewChild('topContent', { static: true }) topContent!: ElementRef;

	routeService = inject(RouteService);
	contentStore = inject(ContentStore);
	dialogService = inject(DialogService);
	eventService = inject(EventService);
	renderer = inject(Renderer2);
	destroyRef = inject(DestroyRef);

	isSideNavOpen = signal<boolean>(false);
	// TODO: clean up
	ngOnInit(): void {
		this.dialogService.sideNavOpen$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(result => {
			if (!result) this.removeStickyHeader();
			if (result) this.addStickyHeader();
			this.isSideNavOpen.set(result);
		});
	}

	@HostListener('window:scroll', ['$event'])
	onWindowScroll(e: any) {
		if (!this.header || !this.topContent) return;
		if (window.scrollY > this.topContent.nativeElement.clientHeight && !this.isSideNavOpen())
			return this.addStickyHeader();
		this.removeStickyHeader();
	}

	private addStickyHeader(): void {
		this.renderer.addClass(this.header.nativeElement, 'd-sticky');
		this.renderer.addClass(this.topContent.nativeElement, 'd-none');
	}

	private removeStickyHeader(): void {
		this.renderer.removeClass(this.header.nativeElement, 'd-sticky');
		this.renderer.removeClass(this.topContent.nativeElement, 'd-none');
	}

	public navLinkClasses(link: SiteLinkModel, currentRoute: string | null): string {
		return currentRoute === link.link || this.dialogService.activeLink()?.display_name === link.display_name
			? 'header__nav-link header__nav-link--active'
			: 'header__nav-link';
	}

	public setActiveLink(link: SiteLinkModel): void {
		this.dialogService.activeLink()?.display_name === link.display_name
			? this.dialogService.closeSubNavigation()
			: this.dialogService.openSubNavigation(link);
	}

	public handleSearch(event: string) {
		this.contentStore.getPagesBySearchTerm(event);
	}
	public handleSearchReset() {
		this.contentStore.pageSearchResults.set([]);
	}

	public navigateHome(): void {
		const link = document.createElement('a');
		link.href = '';
		link.click();
	}
}
