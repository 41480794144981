@if(shouldDisplayLoader()){
<uis-optimized-image [source]="ICON.LoaderIcon" height="100" width="100" alt="loading indicator"> </uis-optimized-image>
} @else {
<form class="provider-form">
	<div class="provider-form__input-container">
		<div>
			<label class="provider-form__input-label"><b>Which service</b> do you need?</label>
			<uis-basic-selector [formInput]="getFormControl('provider')" [options]="options"></uis-basic-selector>
		</div>
		<div>
			<label class="provider-form__input-label">Did you know you can <b>do it yourself?</b></label>
			<p class="provider-form__input-label">
				Save time & money; and sign in to do the vehicle self inspection on your mobile.
			</p>
		</div>
		<div>
			<label class="provider-form__input-label"><b>Where</b> are you?</label>
			@if (thirdPartyScriptService.shouldLoadMap()) {
			<wyzeweb-google-autocomplete
				(userLocationUpdated)="handleUserMarkerUpdated($event)"></wyzeweb-google-autocomplete>
			}
		</div>
	</div>
</form>
}
<div class="service-provider">
	@if (thirdPartyScriptService.shouldLoadMap()) {
	<div class="map" [class.hide]="!userMarker() || !getFormControl('provider').value || shouldDisplayLoader()">
		<wyzeweb-google-map
			[userMarker]="userMarker()"
			[destinationMarker]="destinationMarker()"
			[nearestMarkers]="nearestMarkers()"
			(destinationLocationUpdated)="handleDestinationMarkerClick($event)"
			(routeUpdated)="handleRouteUpdated($event)">
		</wyzeweb-google-map>

		<div class="map__legend">
			<div class="map__legend-item">
				Your location
				<uis-optimized-image [source]="ICON.LocationIcon" height="20" width="20" alt="my location tag">
				</uis-optimized-image>
			</div>
			<div class="map__legend-item">
				Service provider
				<uis-optimized-image
					[source]="ICON.ProviderIcon"
					height="20"
					width="20"
					alt="service provider location tag">
				</uis-optimized-image>
			</div>
		</div>
	</div>
	} @if (selectedProvider() && route() ) {

	<div class="provider-info">
		<p class="provider-info__name">{{ selectedProvider().name }}</p>
		<div>
			<p>Location</p>
			<b><p [innerHTML]="selectedProvider().streetAddress"></p></b>
		</div>
		<div>
			<p>Phone number</p>
			<b><p [innerHTML]="selectedProvider().contactNumber"></p></b>
		</div>
		<div>
			<p>Operating hours</p>
			<b><p [innerHTML]="selectedProvider().tradingHours"></p></b>
		</div>
		<div>
			<p>Distance</p>
			<b
				><p>{{route().routes?.[0].legs?.[0].distance.text}}</p></b
			>
		</div>
		<div>
			<p>Travel time</p>
			<b
				><p>{{route().routes?.[0].legs?.[0].duration.text}}</p></b
			>
		</div>
	</div>
	}
</div>
@if(nearestProviders.length > 1){
<div class="markers">
	More nearby
	<div class="card-grid">
		@for(card of nearestProviders; track $index){
		<uis-document-card
			(cardButtonClicked)="handleCardEvent($event, card.metadata)"
			[card]="card"></uis-document-card>
		}
	</div>
</div>
}
