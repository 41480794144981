<div id="custom-ribbon-badge" class="ribbon-badge badge">
	<a href="https://www.hellopeter.com/iwyze" target="_blank" rel="noopener noreferrer">
		<div class="badge-content-container">
			<div class="logo-container">
				<uis-optimized-image
					class="align-logo"
					[source]="ICON.HelloPerterLogo"
					height="50"
					width="100"
					alt="hellopeter logo"></uis-optimized-image>
				<div class="grey-line-container">
					<img [src]="ICON.GreyLine" alt="grey line" />
				</div>
			</div>
			<div class="stars-text-container">
				<div class="stars-container">
					<ng-container *ngFor="let star of stars">
						<uis-optimized-image [source]="ICON.Star" height="22" width="22" alt="rating star">
						</uis-optimized-image>
					</ng-container>
					<uis-optimized-image
						[source]="ICON.GreySTar"
						height="22"
						width="22"
						alt="grey star"></uis-optimized-image>
				</div>
				<p class="badge-content badge-text">
					<strong>{{ (reviewsData$ | async)?.rating }}</strong> based on
					<strong>{{ (reviewsData$ | async)?.reviews }}</strong> reviews
				</p>
			</div>
		</div>
	</a>
</div>
