import { Component, Input, inject, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BasicCardComponent, SliderComponent } from '@i-wyze/ui-shared-library';
import { EventService } from '../../services/event.service';

@Component({
	selector: 'wyzeweb-view-all-slider',
	standalone: true,
	imports: [CommonModule, BasicCardComponent, SliderComponent],
	templateUrl: './view-all-slider.component.html',
	styleUrl: './view-all-slider.component.scss'
})
export class ViewAllSliderComponent {
	@Input() data: any;
	@Input() index: any;

	eventService = inject(EventService);

	shouldDisplayAllItems = signal<boolean>(false);
}
