import { Component, Input, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoIframeComponent } from '@i-wyze/ui-shared-library';

@Component({
	selector: 'wyzeweb-loose-text',
	standalone: true,
	imports: [CommonModule, VideoIframeComponent],
	templateUrl: './loose-text.component.html',
	styleUrl: './loose-text.component.scss',
	encapsulation: ViewEncapsulation.None
})
export class LooseTextComponent {
	@Input() data!: any;

	get iframeData(): any {
		return {
			name: this.data.content?.items[0]?.iframe?.name,
			title: this.data.content?.items[0]?.iframe?.title,
			src: this.data.content?.items[0]?.iframe?.src,
			aspectRatio: this.data.content?.items[0]?.iframe?.aspect_ratio,
			width: this.data.content?.items[0]?.iframe?.width,
			height: this.data.content?.items[0]?.iframe?.height,
			autoplay: this.data.content?.items[0]?.iframe?.autoplay
		};
	}
}
