import { Component, Input, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'wyzeweb-loose-text',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './loose-text.component.html',
	styleUrl: './loose-text.component.scss',
	encapsulation: ViewEncapsulation.None
})
export class LooseTextComponent {
	@Input() data: any;
}
