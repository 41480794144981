import { AfterViewInit, Component, ElementRef, inject, Input, OnInit, Renderer2, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PipesModule, UiSharedLibraryModule, LeadCaptureComponent } from '@i-wyze/ui-shared-library';
import { EventService } from '../../services/event.service';
import { configMap } from './input-config';
import { InputConfig } from '@i-wyze/ui-shared-library/lib/forms/forms';
import { SessionStorageService } from '../../services/session.service';
import { SessionStorageKey } from '../../enums/session-storage-key.enum';
import { DEPARTMENT } from '../../enums/event.enums';

@Component({
	selector: 'wyzeweb-post',
	standalone: true,
	imports: [CommonModule, UiSharedLibraryModule, PipesModule, LeadCaptureComponent],
	templateUrl: './post.component.html',
	styleUrl: './post.component.scss'
})
export class PostComponent implements AfterViewInit, OnInit {
	@Input() data: any;
	width: any;
	height: any;
	elementRef = inject(ElementRef);
	renderer = inject(Renderer2);
	eventService = inject(EventService);
	sessionService = inject(SessionStorageService);
	inputConfig!: { [key: string]: InputConfig[] };

	ngAfterViewInit(): void {
		setTimeout(() => {
			this.handleResize();
		}, 500);
	}

	ngOnInit(): void {
		this.inputConfig = configMap[this.data.content.items[0]?.lead_capture?.type] || null;
	}

	public get post() {
		return this.data.content.items[0];
	}

	public get hasSources() {
		return this.data.content.items[0].sources.length === 0;
	}

	handleResize(): void {
		const resizeElement = this.elementRef.nativeElement.querySelector('#screen-resize');
		if (!resizeElement) return;
		const dataSizes = resizeElement.getAttribute('data-sizes');

		if (dataSizes) {
			const sizesArray = dataSizes.split(',');
			if (window.innerWidth >= 768) {
				this.width = sizesArray[0]?.trim();
				this.height = sizesArray[1]?.trim();
			} else {
				if (sizesArray.length >= 4 && window.innerWidth < 768) {
					this.width = sizesArray[2]?.trim();
					this.height = sizesArray[3]?.trim();
				} else {
					this.width = sizesArray[0]?.trim();
					this.height = sizesArray[1]?.trim();
				}
			}
			this.renderer.setStyle(resizeElement, 'width', this.width ? `${this.width}px` : '100%');
			this.renderer.setStyle(resizeElement, 'height', this.height ? `${this.height}px` : 'auto');
		}
	}

	get topHeadingIframeData(): any {
		return {
			name: this.data.content?.items[0]?.top_heading_iframe?.name,
			title: this.data.content?.items[0]?.top_heading_iframe?.title,
			src: this.data.content?.items[0]?.top_heading_iframe?.src,
			aspectRatio: this.data.content?.items[0]?.top_heading_iframe?.aspect_ratio,
			width: this.data.content?.items[0]?.top_heading_iframe?.width,
			height: this.data.content?.items[0]?.top_heading_iframe?.height,
			autoplay: this.data.content?.items[0]?.top_heading_iframe?.autoplay,
			type: this.data.content?.items[0]?.top_heading_iframe?.type
		};
	}
	get bottomHeadingIframeData(): any {
		return {
			name: this.data.content?.items[0]?.bottom_heading_iframe?.name,
			title: this.data.content?.items[0]?.bottom_heading_iframe?.title,
			src: this.data.content?.items[0]?.bottom_heading_iframe?.src,
			aspectRatio: this.data.content?.items[0]?.bottom_heading_iframe?.aspect_ratio,
			width: this.data.content?.items[0]?.bottom_heading_iframe?.width,
			height: this.data.content?.items[0]?.bottom_heading_iframe?.height,
			autoplay: this.data.content?.items[0]?.bottom_heading_iframe?.autoplay,
			type: this.data.content?.items[0]?.bottom_heading_iframe?.type
		};
	}
	get subHeadingIframeData(): any {
		return {
			name: this.data.content?.items[0]?.sub_heading_iframe?.name,
			title: this.data.content?.items[0]?.sub_heading_iframe?.title,
			src: this.data.content?.items[0]?.sub_heading_iframe?.src,
			aspectRatio: this.data.content?.items[0]?.sub_heading_iframe?.aspect_ratio,
			width: this.data.content?.items[0]?.sub_heading_iframe?.width,
			height: this.data.content?.items[0]?.sub_heading_iframe?.height,
			autoplay: this.data.content?.items[0]?.sub_heading_iframe?.autoplay,
			type: this.data.content?.items[0]?.sub_heading_iframe?.type
		};
	}
	get conclusionIframeData(): any {
		return {
			name: this.data.content?.items[0]?.conclusion_iframe?.name,
			title: this.data.content?.items[0]?.conclusion_iframe?.title,
			src: this.data.content?.items[0]?.conclusion_iframe?.src,
			aspectRatio: this.data.content?.items[0]?.conclusion_iframe?.aspect_ratio,
			width: this.data.content?.items[0]?.conclusion_iframe?.width,
			height: this.data.content?.items[0]?.conclusion_iframe?.height,
			autoplay: this.data.content?.items[0]?.conclusion_iframe?.autoplay,
			type: this.data.content?.items[0]?.conclusion_iframe?.type
		};
	}
	get hasButtons(): boolean {
		return !!this.data?.content?.items?.[0]?.buttons?.length;
	}

	get hasLeadCapture(): boolean {
		return !!this.data?.content?.items?.[0]?.lead_capture;
	}

	get leadCaptureData(): any {
		return {
			name: this.data.content?.items[0]?.lead_capture?.name,
			title: this.data.content?.items[0]?.lead_capture?.title,
			type: this.data.content?.items[0]?.lead_capture?.type,
			product_categories: this.data.content?.items[0]?.lead_capture?.product_categories,
			buttons: this.data.content?.items[0]?.lead_capture?.buttons
		};
	}
	handleButtonClick(event: string): void {
		this.eventService.handleEvent({ event, redirectUrl: this.data.learn_more_url });
	}

	handleFormSubmission(event: any): void {
		if (!event.metadata || !event.metadata.product_code)
			event.metadata = { ...event.metadata, ...this.data.content.items[0].product };
		event.metadata.origin = this.sessionService.getItem(SessionStorageKey.ORIGIN);
		if (event.metadata.idNumber && !event.metadata.department) event.metadata.department = DEPARTMENT.CLAIMS;
		this.eventService.handleEvent(event);
	}
}
