import { Component, Input, inject, OnChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ICON, UiSharedLibraryModule } from '@i-wyze/ui-shared-library';
import { ContentStore } from '../../../../services/content.store';
import { SiteLinkModel } from '../../../../models/nucleus.model';

@Component({
	selector: 'wyzeweb-sub-menu',
	standalone: true,
	imports: [CommonModule, UiSharedLibraryModule],
	templateUrl: './sub-menu.component.html',
	styleUrl: './sub-menu.component.scss'
})
export class SubMenuComponent implements OnChanges {
	public readonly ICON = ICON;

	@Input() link!: SiteLinkModel;

	contentStore = inject(ContentStore);

	public linkGroups: { groupMainLink: SiteLinkModel; subLinks: SiteLinkModel[] }[] = [];

	ngOnChanges(): void {
		this.getLinkGroups();
	}

	public getLinkGroups(): void {
		if (!this.link?.sub_link_group) return;

		const linkGroups = this.contentStore
			.filterLinksByGroup(this.link.sub_link_group)
			?.filter(x => !!x.sub_link_group);
		this.linkGroups = linkGroups.map(x => ({
			groupMainLink: x,
			subLinks: this.contentStore.filterLinksByGroup(x.sub_link_group!)
		}));
	}
}
