import { Component, Input, OnInit, inject, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, FormsModule, Validators } from '@angular/forms';
import { FormValidators, ICON, SelectableOption, UiSharedLibraryModule } from '@i-wyze/ui-shared-library';
import { EventService } from '../../services/event.service';
import { DEPARTMENT, EVENT_TYPE } from '../../enums/event.enums';
import { ContentStore } from '../../services/content.store';

@Component({
	selector: 'wyzeweb-contact-form',
	standalone: true,
	imports: [CommonModule, FormsModule, UiSharedLibraryModule],
	templateUrl: './contact-form.component.html',
	styleUrl: './contact-form.component.scss'
})
export class ContactFormComponent implements OnInit {
	public readonly ICON = ICON;
	@Input() data: any;
	contactForm!: FormGroup;

	formBuilder = inject(FormBuilder);
	eventService = inject(EventService);
	contentStore = inject(ContentStore);

	hasSubmitted = signal<boolean>(false);

	departmentOptions = [
		{ title: 'Quotes', value: DEPARTMENT.QUOTES },
		{ title: 'Claims', value: DEPARTMENT.CLAIMS },
		{ title: 'Customer care', value: DEPARTMENT.CUSTOMER_CARE },
		{ title: 'Complaints', value: DEPARTMENT.COMPLAINTS }
	];

	ngOnInit(): void {
		this.contactForm = this.formBuilder.group({
			fullName: ['', FormValidators.required('name')],
			cellphoneNumber: ['', [FormValidators.required('cellphone number'), FormValidators.cellphoneNumber()]],
			department: ['', FormValidators.required('department', 'You must make a selection')],
			product: ['', FormValidators.required('product', 'You must make a selection')],
			message: ['']
		});
		this.contentStore.loadProducts();
	}

	get productOptions(): SelectableOption[] {
		return this.contentStore.products().map(option => {
			return {
				title: option.name,
				value: option
			};
		});
	}

	public getFormControl(name: string) {
		return this.contactForm.get(name) as FormControl;
	}

	onSubmit() {
		if (this.contactForm.valid) {
			this.eventService.handleEvent({
				event: EVENT_TYPE.CALL_BACK,
				metadata: {
					...this.contactForm.value,
					...this.contactForm.value.product,
					firstName: this.contactForm.value.fullName.split(' ')[0],
					surname: this.contactForm.value.fullName.split(' ')[1],
					origin: document.referrer,
					department:
						this.contactForm.value.department === DEPARTMENT.QUOTES
							? null
							: this.contactForm.value.department
				}
			});
			this.hasSubmitted.set(false);
		} else {
			this.hasSubmitted.set(true);
		}
	}
}
