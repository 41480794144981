@if(data.component_type.includes('BANNER')) {
<ng-template nxNgNestDynamicComponent [componentType]="data?.component_type" [data]="data"></ng-template>
} @else if(isOpen()) {
<section class="page-section">
	<h3 *ngIf="data.heading" [class]="sectionTitleClass" [innerHTML]="data?.heading"></h3>
	<uis-optimized-image
		*ngIf="!data.is_collapsable && data?.icon_name && !!getIconPath(data?.icon_name)"
		[source]="getIconPath(data?.icon_name)"
		height="80"
		width="80"
		alt="product icon">
	</uis-optimized-image>
	<p *ngIf="data?.sub_heading" class="page-section__paragraph-text" [innerHTML]="data?.sub_heading"></p>

	@if (data.additional_content) {
	<div
		class="page-section__paragraph-text page-section__paragraph-text--left"
		[innerHTML]="data.additional_content"></div>
	}

	<div class="page-section__content">
		<ng-template
			nxNgNestDynamicComponent
			[componentType]="data?.component_type"
			[data]="data"
			[index]="index"></ng-template>
	</div>
	<uis-button *ngIf="data?.is_collapsable" (click)="isOpen.set(false)" [type]="BUTTON_TYPE.TEXT">See less</uis-button>
	<uis-button-group
		*ngIf="data?.buttons.length > 0"
		(buttonClicked)="handleButtonClick($event)"
		[buttons]="data?.buttons"></uis-button-group>
	<hr *ngIf="data?.has_divider" class="page-section__divider" />
</section>
} @else {
<section class="section-closed">
	<div [class]="sectionClosedContentClass">
		<div class="section-closed__content-container">
			<uis-optimized-image [source]="getIconPath(data?.icon_name)" height="24" width="24" alt="product icon">
			</uis-optimized-image>
			<h3 class="page-section__title" [innerHTML]="data?.heading"></h3>
		</div>
		<div class="section-closed__content-container">
			<uis-button (click)="isOpen.set(true)" [type]="BUTTON_TYPE.TEXT">See more</uis-button>
			<span (click)="isOpen.set(true)">+</span>
		</div>
	</div>
</section>
}
