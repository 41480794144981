import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideRouter, withEnabledBlockingInitialNavigation } from '@angular/router';
import { appRoutes } from './app.routes';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { provideClientHydration } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxMaskModule } from 'ngx-mask';
import {
	GoogleTagManagerDataProvider,
	GoogleTagManagerService,
	NotificationService,
	WindowService
} from '@i-wyze/ui-shared-library';
import { GtmDataProvider } from './shared/services/gtm-data-provider';
import { AppWindowService } from './shared/services/window.service';

export const appConfig: ApplicationConfig = {
	providers: [
		provideRouter(appRoutes, withEnabledBlockingInitialNavigation()),
		provideHttpClient(withFetch()),
		provideClientHydration(),
		importProvidersFrom([BrowserAnimationsModule, NgxMaskModule.forRoot()]),
		NotificationService,
		GoogleTagManagerService,
		{ provide: GoogleTagManagerDataProvider, useClass: GtmDataProvider },
		{ provide: WindowService, useExisting: AppWindowService }
	]
};
