<div class="post">
	<div class="post__content-group">
		<p class="font-tertiary font-tertiary--color-light">
			This post was published on {{ post.publish_date | date }}
		</p>
	</div>
	<div class="post__content-group">
		<h2 class="post__heading" [innerHTML]="post.blog_heading"></h2>
		<ng-container *ngIf="data?.content?.items[0]?.top_heading_iframe">
			<uis-video-iframe [iframe]="topHeadingIframeData"></uis-video-iframe>
		</ng-container>
		<p [innerHTML]="post.heading_content | sanitizeHtml"></p>
		<ng-container *ngIf="data?.content?.items[0]?.bottom_heading_iframe">
			<uis-video-iframe [iframe]="bottomHeadingIframeData"></uis-video-iframe>
		</ng-container>
	</div>
	<div class="post__content-group">
		<h3 class="post__heading" [innerHTML]="post.sub_heading"></h3>
		<p [innerHTML]="post.sub_heading_content | sanitizeHtml"></p>
		<div *ngIf="hasButtons" class="button">
			<uis-button-group
				(buttonClicked)="handleButtonClick($event)"
				[buttons]="data.content.items[0].buttons"></uis-button-group>
		</div>
		<div *ngIf="hasLeadCapture">
			<uis-lead-capture
				[data]="leadCaptureData"
				[inputConfig]="inputConfig"
				[wasSubmissionSuccessful]="eventService.wasLeadSubmissionSuccessful()"
				(formSubmitted)="handleFormSubmission($event)"></uis-lead-capture>
		</div>
		<ng-container *ngIf="data?.content?.items[0]?.sub_heading_iframe">
			<uis-video-iframe [iframe]="subHeadingIframeData"></uis-video-iframe>
		</ng-container>
	</div>
	<h3 class="post__heading post__heading--bottom-margin" [innerHTML]="post.primary_list_heading"></h3>
	<ol class="post__content-group">
		@for (item of post.primary_list_items; track $index) {
		<li class="post__list-item">
			<b *ngIf="item.heading">{{ item.heading }}</b
			><span [innerHTML]="item.content | sanitizeHtml"></span>
		</li>
		}
	</ol>
	<div class="post__content-group">
		<!-- special circumstance <img> -->
		<img width="100%" *ngIf="post.desktop_image" [src]="post.desktop_image" alt="blog image" />
	</div>
	<h3
		*ngIf="post.secondary_list_heading"
		class="post__heading post__heading--bottom-margin"
		[innerHTML]="post.secondary_list_heading | sanitizeHtml"></h3>
	<ol class="post__content-group">
		@for (item of post.secondary_list_items; track $index) {
		<li class="post__list-item">
			<b *ngIf="item.heading" [innerHTML]="item.heading"></b
			><span [innerHTML]="item.content | sanitizeHtml"></span>
		</li>
		}
	</ol>
	<div *ngIf="post.table" class="post__content-group" [innerHtml]="post.table | sanitizeHtml"></div>
	<div class="post__content-group">
		<p [innerHTML]="post.conclusion | sanitizeHtml"></p>
		<ng-container *ngIf="data?.content?.items[0]?.conclusion_iframe">
			<uis-video-iframe [iframe]="conclusionIframeData"></uis-video-iframe>
		</ng-container>
	</div>
	<ul class="post__content-group">
		<p *ngIf="!hasSources" class="font-tertiary font-tertiary--weight-bold">Sources:</p>
		@for (source of post.sources; track $index) {
		<a [href]="source.path">{{ source.display_name }}</a>
		}
	</ul>
</div>
