import { Component, Input, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabComponent } from '@i-wyze/ui-shared-library';
import { ContentStore } from '../../services/content.store';

@Component({
	selector: 'wyzeweb-recent-posts',
	standalone: true,
	imports: [CommonModule, TabComponent],
	templateUrl: './recent-posts.component.html',
	styleUrl: './recent-posts.component.scss'
})
export class RecentPostsComponent implements OnInit {
	@Input() data!: any;
	@Input() index!: any;

	contentStore = inject(ContentStore);

	tabs = [
		{
			title: 'All',
			variant: 'general',
			value: 'all',
			click_emission: 'filter'
		},
		{
			title: 'Car',
			variant: 'car',
			value: 'car',
			click_emission: 'filter'
		},
		{
			title: 'Home',
			variant: 'home',
			value: 'home',
			click_emission: 'filter'
		},
		{
			title: 'Life',
			variant: 'life',
			value: 'life',
			click_emission: 'filter'
		},
		{
			title: 'More',
			variant: 'more',
			value: 'more',
			click_emission: 'filter'
		}
	];

	ngOnInit(): void {
		this.contentStore.getRecentPosts('all');
	}

	handleTabClicked(event: any): void {
		this.contentStore.getRecentPosts(event.metadata.value);
	}
}
