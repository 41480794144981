<nav>
	<ng-container *ngIf="listConfig.title">
		<div class="nav-list__title d-flex" (click)="goBack()">
			{{ listConfig.title }}
			<wyzeweb-optimized-image [source]="ICON.ArrowUpIcon" height="15" width="15"> </wyzeweb-optimized-image>
		</div>
	</ng-container>
	<ul class="nav-list">
		<ng-container *ngFor="let link of listConfig.links; let i = index">
			<li [class]="listItemClass(link)">
				<ng-container *ngIf="link.has_subLinks">
					<span [class]="getLinkClass(link)" (click)="activateSubMenu(link)">
						{{ link.display_name }}
						<wyzeweb-optimized-image
							class="nav-list__link-icon"
							[source]="ICON.ArrowRightIcon"
							height="15"
							width="15"
							alt="sub menu arrow icon">
						</wyzeweb-optimized-image>
					</span>
				</ng-container>
				<ng-container *ngIf="!link.has_subLinks">
					<a [class]="getLinkClass(link)" [href]="link.link">{{ link.display_name }}</a>
				</ng-container>
			</li>
		</ng-container>
	</ul>
</nav>
