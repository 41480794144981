import { Directive, Input, OnChanges, OnInit, SimpleChanges, ViewContainerRef, inject } from '@angular/core';
import { TestimonialSliderComponent } from '../components/testimonial-slider/testimonial-slider.component';
import { CustomerFeedbackSliderComponent } from '../components/customer-feedback-slider/customer-feedback-slider.component';
import { EntriesComponent } from '../components/entries/entries.component';
import { PyramidComponent } from '../components/pyramid/pyramid.component';
import { GridComponent } from '../components/grid/grid.component';
import { ContactFormComponent } from '../components/contact-form/contact-form.component';
import { ServiceProviderComponent } from '../components/service-provider/service-provider.component';
import { ProductListComponent } from '../components/product-list/product-list.component';
import { BannerSliderComponent } from '../components/banner-slider/banner-slider.component';
import { BannerComponent } from '../components/banner/banner.component';
import { PostComponent } from '../components/post/post.component';
import { ViewAllSliderComponent } from '../components/view-all-slider/view-all-slider.component';
import { InformationItemComponent } from '../components/information-item/information-item.component';
import { LooseTextComponent } from '../components/loose-text/loose-text.component';
import { ColumnComponent } from '../components/column/column.component';
import { CubeComponent } from '../components/cube/cube.component';
import { RecentPostsComponent } from '../components/recent-posts/recent-posts.component';
import { SocialListComponent } from '../components/social-list/social-list.component';
import { CustomerFeedbackPageComponent } from '../components/customer-feedback-page/customer-feedback-page.component';

export const componentMapper = {
	SLIDER: TestimonialSliderComponent,
	FEEFO: CustomerFeedbackSliderComponent,
	CUSTOMER_FEEDBACK_PAGE: CustomerFeedbackPageComponent,
	PAGINATOR: EntriesComponent,
	PYRAMID: PyramidComponent,
	GRID: GridComponent,
	CONTACT_FORM: ContactFormComponent,
	SERVICE_PROVIDER: ServiceProviderComponent,
	LOOSE_TEXT: LooseTextComponent,
	PRODUCT_LIST: ProductListComponent,
	BANNER_SLIDER: BannerSliderComponent,
	BANNER: BannerComponent,
	BLOG: PostComponent,
	VIEW_ALL_SLIDER: ViewAllSliderComponent,
	INFORMATION: InformationItemComponent,
	COLUMN: ColumnComponent,
	CUBE: CubeComponent,
	RECENT: RecentPostsComponent,
	SOCIAL: SocialListComponent
	// anotherComponentType: AnotherComponent,
	// add more mappings as needed
};

@Directive({
	selector: '[nxNgNestDynamicComponent]',
	standalone: true
})
export class DynamicComponentLoaderDirective implements OnInit, OnChanges {
	@Input() componentType = '';
	@Input() data!: any;
	@Input() index!: number;

	viewContainerRef = inject(ViewContainerRef);
	componentRef: any;

	ngOnInit() {
		const component = componentMapper[this.componentType as keyof typeof componentMapper];

		if (component) {
			this.viewContainerRef.clear();
			// @ts-ignore
			this.componentRef = this.viewContainerRef.createComponent(component);
			this.componentRef.instance.data = this.data;
			this.componentRef.instance.index = this.index;
			if (this.componentRef.instance.sectionOrder) this.componentRef.instance.sectionOrder = this.data.order;
		} else {
			console.warn(`No component found for type ${this.componentType}`);
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (this.componentRef?.instance) {
			this.componentRef.instance.data = this.data;
			this.componentRef.instance.index = this.index;
		}
	}
}
