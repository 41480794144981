import { CommonModule } from '@angular/common';
import {
	Component,
	DestroyRef,
	Directive,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	ViewContainerRef,
	inject
} from '@angular/core';
import {
	AccordionCardComponent,
	BasicCardComponent,
	DocumentCardComponent,
	ListCardComponent
} from '@i-wyze/ui-shared-library';
import { EventService } from '../services/event.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CardEvent } from '@i-wyze/ui-shared-library/lib/cards/interfaces/card.interfaces';

const componentMapper = {
	BASIC: BasicCardComponent,
	DOCUMENT: DocumentCardComponent,
	ACCORDION: AccordionCardComponent,
	LIST: ListCardComponent
};

@Directive({
	selector: '[nxNgNestDynamicCardComponent]',
	standalone: true
})
export class DynamicComponentCardLoaderDirective implements OnInit, OnChanges {
	@Input() data!: any;
	@Input() index!: number;
	@Output() cardButtonClicked = new EventEmitter<CardEvent>();

	viewContainerRef = inject(ViewContainerRef);
	destroyRef = inject(DestroyRef);
	componentRef: any;

	ngOnInit() {
		const component = componentMapper[this.data.card_type as keyof typeof componentMapper];

		if (component) {
			this.viewContainerRef.clear();
			// @ts-ignore
			this.componentRef = this.viewContainerRef.createComponent(component);
			this.componentRef.instance.card = this.data;
			this.componentRef.instance.cardButtonClicked
				.pipe(takeUntilDestroyed(this.destroyRef))
				.subscribe((event: any) => {
					this.cardButtonClicked.emit(event);
				});
		} else {
			console.warn(`No component found for type ${this.data.card_type}`);
		}
	}

	ngOnChanges(): void {
		if (this.componentRef?.instance) this.componentRef.instance.card = this.data;
	}
}

@Component({
	selector: 'wyzeweb-dynamic-card-loader',
	standalone: true,
	imports: [CommonModule, DynamicComponentCardLoaderDirective],
	template: ` <ng-template
		nxNgNestDynamicCardComponent
		(cardButtonClicked)="handleCardEvent($event)"
		[data]="data"
		[index]="index"></ng-template>`
})
export class DynamicCardComponent {
	@Input() data!: any;
	@Input() index!: number;

	eventService = inject(EventService);

	handleCardEvent(event: CardEvent) {
		this.eventService.handleEvent(event);
	}
}
