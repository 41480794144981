import { Route } from '@angular/router';
import { TempComponent } from './pages/temp/temp.component';
import { LayoutComponent } from './shared/components/page-furniture/layout/layout.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { RouteResolverService } from './shared/services/route-resolver.service';

//TODO: add auth guards for 'signed in' content
//TODO: change ladning page component name

export const appRoutes: Route[] = [
	{
		path: '',
		component: LayoutComponent,
		children: [
			{
				path: '',
				component: LandingPageComponent,
				pathMatch: 'full',
				resolve: {
					myData: RouteResolverService
				}
			},
			{
				path: 'products',
				component: LandingPageComponent,
				resolve: {
					myData: RouteResolverService
				}
			},
			{
				path: 'products/:slug',
				component: LandingPageComponent,
				resolve: {
					myData: RouteResolverService
				}
			},
			{
				path: 'home/:slug',
				component: LandingPageComponent,
				resolve: {
					myData: RouteResolverService
				}
			},
			{
				path: 'claim',
				component: LandingPageComponent,
				resolve: {
					myData: RouteResolverService
				}
			},
			{
				path: 'quote',
				component: LandingPageComponent,
				resolve: {
					myData: RouteResolverService
				}
			},
			{
				path: 'support',
				component: LandingPageComponent,
				resolve: {
					myData: RouteResolverService
				}
			},
			{
				path: 'support/:slug',
				component: LandingPageComponent,
				resolve: {
					myData: RouteResolverService
				}
			},
			{
				path: 'support/tips-and-tools/tips/:slug',
				component: LandingPageComponent,
				resolve: {
					myData: RouteResolverService
				}
			},
			{
				path: 'blogs',
				component: LandingPageComponent,
				resolve: {
					myData: RouteResolverService
				}
			},
			{
				path: 'post/:slug',
				component: LandingPageComponent,
				resolve: {
					myData: RouteResolverService
				}
			},
			{
				path: 'contact',
				component: LandingPageComponent,
				resolve: {
					myData: RouteResolverService
				}
			},
			{
				path: ':slug',
				component: LandingPageComponent,
				resolve: {
					myData: RouteResolverService
				}
			}
		]
	},
	{ path: '**', component: TempComponent } // TODO: 404 page goes here
];
