import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiSharedLibraryModule } from '@i-wyze/ui-shared-library';
import { InformationItemModel } from '../../models/nucleus.model';

@Component({
	selector: 'wyzeweb-information-item',
	standalone: true,
	imports: [CommonModule, UiSharedLibraryModule],
	templateUrl: './information-item.component.html',
	styleUrl: './information-item.component.scss'
})
export class InformationItemComponent {
	@Input() data!: InformationItemModel;
	@Input() index!: number;
}
