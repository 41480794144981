import { inject, Injectable, PLATFORM_ID, signal } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { environment } from 'apps/ng/src/environments/environment';

declare const google: any;

@Injectable({
	providedIn: 'root'
})
export class ThirdPartyScriptService {
	platformId = inject(PLATFORM_ID);

	shouldLoadMap = signal<boolean>(false);

	private loadOnBrowser(path: string, name: string, cookieCategory: string) {
		// TODO: reimplement during hello peter integration
		setTimeout(() => this.shouldLoadMap.set(true), 5000);
		// if (isPlatformBrowser(this.platformId))
		// 	this.loadScript(path, name, cookieCategory)
		// 		.then(() => setTimeout(() => this.shouldLoadMap.set(true), 5000))
		// 		.catch(err => console.error(`Failed to load ${name} script:`, err));
	}

	private async loadScript(path: string, name: string, cookieCategory: string): Promise<void> {
		const response = await fetch(path);
		if (!response.ok) {
			throw new Error(`HTTP error! status: ${response.status}`);
		}
		const scriptText = await response.text();
		const scriptElement = document.createElement('script');
		scriptElement.textContent = scriptText;
		scriptElement.type = 'text/plain';
		scriptElement.setAttribute('data-cookiescript', 'accepted');
		scriptElement.setAttribute('data-cookiecategory', cookieCategory);
		scriptElement.async = true;
		scriptElement.defer = true;
		scriptElement.onerror = () => console.error(`Failed to load ${name} script`);
		document.head.appendChild(scriptElement);
		console.log(`${name} script loaded successfully`);
	}

	public loadGoogleMapsScript() {
		this.loadOnBrowser('/google-maps-script', 'Google Maps', 'functionality');
	}

	public injectGtmScript(): void {
		const gtmScript = document.createElement('script');
		gtmScript.async = true;
		gtmScript.defer = true;
		gtmScript.innerHTML = `
		  (function (w, d, s, l, i) {
			w[l] = w[l] || [];
			w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
			var f = d.getElementsByTagName(s)[0],
				j = d.createElement(s),
				dl = l != 'dataLayer' ? '&l=' + l : '';
			j.async = true;
			j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
			f.parentNode.insertBefore(j, f);
		  })(window, document, 'script', 'dataLayer', '${environment.googleTagId}');
	
		  window.dataLayer = window.dataLayer || [];
		  function gtag() {
			dataLayer.push(arguments);
		  }
		  gtag('js', new Date());
		  gtag('config', '${environment.googleTagId}');
		`;
		document.head.appendChild(gtmScript);

		// Optional: Inject the noscript GTM iframe
		const noScript = document.createElement('noscript');
		noScript.innerHTML = `
		  <iframe
			src="https://www.googletagmanager.com/ns.html?id=${environment.googleTagId}"
			height="0" width="0"
			style="display: none; visibility: hidden"
		  ></iframe>
		`;
		document.body.appendChild(noScript);
	}
}
