import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
	selector: 'wyzeweb-temp',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './temp.component.html',
	styleUrl: './temp.component.scss'
})
export class TempComponent {}
