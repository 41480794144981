import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { ContentStore } from './content.store';

@Injectable({
	providedIn: 'root'
})
export class RouteResolverService implements Resolve<any> {
	contentStore = inject(ContentStore);

	resolve(route: ActivatedRouteSnapshot): any {
		let slug = route.paramMap.get('slug');
		// @ts-ignore
		if (!slug) slug = route.routeConfig?.path === '' ? 'landing' : route.routeConfig?.path;
		// temp redirect for demo
		if (slug === 'car-general-car-insurance') slug = 'car-insurance';
		if (slug === 'post') slug = 'blog';
		this.contentStore.loadRedirects().then(redirects => {
			if (redirects.length < 1) {
				this.contentStore.loadRedirects().then(redirects => {
					const slugObj = redirects.find(data => data.from_slug === slug);
					if (slugObj) return window.location.replace(slugObj.to_slug);
				});
			} else {
				const slugObj = redirects.find(data => data.from_slug === slug);
				if (slugObj) return window.location.replace(slugObj.to_slug);
			}
			this.contentStore.loadPage(slug);
		});
	}
}
