import { Component, Input, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ICON, UiSharedLibraryModule } from '@i-wyze/ui-shared-library';
import { ContentStore } from '../../services/content.store';

@Component({
	selector: 'wyzeweb-product-list',
	standalone: true,
	imports: [CommonModule, UiSharedLibraryModule],
	templateUrl: './product-list.component.html',
	styleUrl: './product-list.component.scss'
})
export class ProductListComponent implements OnInit {
	public readonly ICON = ICON;
	@Input() data: any;
	@Input() index!: number;

	contentStore = inject(ContentStore);

	ngOnInit() {
		this.contentStore.loadProductCategories();
	}

	getChipClass(variant: string): string {
		return `chip chip--${variant}`;
	}
}
