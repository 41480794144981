import { Component, Input, AfterViewInit, Renderer2, ElementRef, Inject, PLATFORM_ID } from '@angular/core';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { environment } from 'apps/ng/src/environments/environment';

@Component({
	selector: 'wyzeweb-customer-feedback-slider',
	standalone: true,
	imports: [CommonModule],
	templateUrl: './customer-feedback-slider.component.html',
	styleUrl: 'customer-feedback-slider.component.scss'
})
export class CustomerFeedbackSliderComponent implements AfterViewInit {
	@Input() data: any;
	@Input() index!: number;

	constructor(private renderer: Renderer2, private el: ElementRef, @Inject(PLATFORM_ID) private platformId: Object) {}

	ngAfterViewInit(): void {
		if (isPlatformBrowser(this.platformId)) {
			this.loadJQueryScript();
			this.loadVerticalFeedScript();
		}
	}

	private loadJQueryScript(): void {
		const jQueryScript = this.renderer.createElement('script');
		jQueryScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/jquery/3.4.1/jquery.min.js';
		jQueryScript.type = 'text/javascript';
		jQueryScript.async = true;
		this.renderer.appendChild(document.body, jQueryScript);
	}

	private loadVerticalFeedScript(): void {
		const script = this.renderer.createElement('script');
		script.type = 'text/javascript';
		script.async = true;
		script.id = 'hp-widget-code';
		script.setAttribute('data-api', '/api/hello-peter');
		script.setAttribute('data-appUrl', 'https://business.hellopeter.com');
		script.setAttribute('data-search', `${this.brand}`);
		script.setAttribute(
			'data-params',
			'{"minReviewsRequired":{"ratings":[4,5]},"widget_background_color":"original","dimension":"700","font_color":"#58595b","logo_color":"logo_b2c.svg","five_star_rating_color":"#00bf87","average_star_rating_color":"#00bf87","four_star_rating_color":"#30c8a7","widget_header":"metrics","display_industry_ranking":true,"isMobile":false,"previewBackground":"#2484b5","is_logo_disable":true,"previewIframeHeight":"458px","previewIframeWidth":"320px","num_of_reviews":10,"google_metrix":false,"google_reviews":false,"facebook_metrix":false,"facebook_reviews":false,"reviewers_name_as_anonymous":false,"showTagLine":false,"aboutLink":"https://intercom.help/hellopeter/en/articles/4409132-vertical-feed"}'
		);
		script.setAttribute('data-appFront', 'https://www.hellopeter.com');
		script.src = 'https://business.hellopeter.com/static/js/verticalFeed.js?v=2.2';

		this.renderer.appendChild(document.body, script);
	}

	get brand() {
		return environment.brand;
	}
}
