<uis-paginator
	(resetSearch)="handleSearchReset()"
	(searchEvent)="handleSearchEvent($event)"
	(pageChanged)="updatePagination($event)"
	[data]="data"
	[options]="options"
	[searchOptions]="contentStore.pageSearchResults()">
	@if (contentStore.isLoading()) {
	<uis-optimized-image [source]="ICON.LoaderIcon" height="100" width="100" alt="loading indicator">
	</uis-optimized-image>
	} @else {
	<div [class]="getCardContainerClass(data.content.items[0])">
		@for (item of getCurrentDataset; track $index) {
		<wyzeweb-dynamic-card-loader [data]="item"></wyzeweb-dynamic-card-loader>
		}
	</div>
	}
</uis-paginator>
