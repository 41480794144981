import { isPlatformBrowser } from '@angular/common';
import { Component, Injector, OnInit, PLATFORM_ID, inject } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AppDependencyInjector, GoogleTagManagerService, GtmComponent } from '@i-wyze/ui-shared-library';
import { environment } from '../environments/environment';
import { ThirdPartyScriptService } from './shared/services/third-party-script.service';
import { SessionStorageService } from './shared/services/session.service';

@Component({
	standalone: true,
	imports: [RouterModule],
	selector: 'wyzeweb-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
@GtmComponent('WyzeWebApp')
export class AppComponent implements OnInit {
	platformId = inject(PLATFORM_ID);
	thirdPartyScriptService = inject(ThirdPartyScriptService);
	sessionService = inject(SessionStorageService);

	constructor(private googleTagManager: GoogleTagManagerService, injector: Injector) {
		this.initializeInjector(injector);
	}

	ngOnInit(): void {
		if (isPlatformBrowser(this.platformId)) this.thirdPartyScriptService.injectGtmScript();
		this.googleTagManager.trackPageViews();
		this.initializeCookieConsent();
	}

	private initializeCookieConsent(): void {
		// TODO: currently we aren't directly doing anything with this consent same in wyzesabi, department heads are aware
		if (isPlatformBrowser(this.platformId)) {
			(window as any).cookieconsent.initialise({
				palette: {
					popup: {
						background: '#282828'
					},
					button: {
						background: '#fff',
						text: '#000'
					}
				},
				theme: 'classic',
				content: {
					message:
						'<b>The website uses cookies to run essential services and improve or personalise your experience</b>. Certain information we collect automatically when you visit our website. Read more about how we manage your information in our ',
					dismiss: 'ACCEPT COOKIES',
					link: 'privacy policy',
					href: `${environment.apiUrl.split('/api')[0]}/privacy-policy`
				}
			});
		}
	}

	private initializeInjector(injector: Injector): void {
		if (isPlatformBrowser(this.platformId)) {
			try {
				AppDependencyInjector.setInjector(injector);
			} catch (error) {
				console.error('Error setting injector:', error);
			}
		}
	}
}
