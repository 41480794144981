@if(!this.shouldDisplayAllItems()){
<uis-slider ngSkipHydration [items]="data.content.items" [showControls]="true">
	<ng-template let-item="item" let-isCurrentItem="isCurrentItem">
		<div class="view-all__card">
			<uis-basic-card (cardButtonClicked)="eventService.handleEvent($event)" [card]="item"></uis-basic-card>
		</div>
	</ng-template>
</uis-slider>
<button (click)="this.shouldDisplayAllItems.set(true)" class="view-all__btn">View all >></button>
} @else {
<div class="view-all__card-list">
	@for(card of data.content.items; track $index){
	<div class="view-all__card">
		<uis-basic-card (cardButtonClicked)="eventService.handleEvent($event)" [card]="card"></uis-basic-card>
	</div>
	}
</div>
}
