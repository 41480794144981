<div class="column">
	<!-- TODO: will convert to component factory loader -->
	@for (item of data.content.items; track $index) { @if(item.component_type === 'CARD'){
	<wyzeweb-dynamic-card-loader [data]="item"></wyzeweb-dynamic-card-loader>
	} @else if(item.component_type === 'INFORMATION'){
	<wyzeweb-information-item ngSkipHydration [data]="item"></wyzeweb-information-item>
	} @else if(item.component_type === 'LOOSE_TEXT'){
	<wyzeweb-loose-text [data]="{ content: { items: [item] } }"></wyzeweb-loose-text>
	} @else if(item.component_type === 'IMAGE'){
	<uis-optimized-image [source]="item.desktop_image" [height]="item.height" [width]="item.width" [priority]="true">
	</uis-optimized-image>
	} }
</div>
