import { Component, Input, inject, signal } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DynamicComponentLoaderDirective } from '../../directives/dynamic-component-loader.directive';
import { BUTTON_TYPE, ICON, UiSharedLibraryModule } from '@i-wyze/ui-shared-library';
import { EventService } from '../../services/event.service';

@Component({
	selector: 'wyzeweb-section',
	standalone: true,
	imports: [CommonModule, UiSharedLibraryModule, DynamicComponentLoaderDirective],
	templateUrl: './section.component.html',
	styleUrl: './section.component.scss'
})
export class SectionComponent {
	public readonly BUTTON_TYPE = BUTTON_TYPE;
	public readonly ICON = ICON;
	@Input() data: any;
	@Input() index!: number;
	isOpen = signal<boolean>(true);

	eventService = inject(EventService);

	public get sectionTitleClass(): string {
		return this.data?.variant
			? `page-section__title page-section__title--${this.data.variant}`
			: 'page-section__title';
	}

	public getIconPath(name: string): string {
		return ICON[name as keyof typeof ICON];
	}

	handleButtonClick(event: string): void {
		this.eventService.handleEvent({ event, redirectUrl: this.data.learn_more_url });
	}

	get sectionClosedContentClass(): string {
		return `section-closed__content section-closed__content--${this.data?.variant}`;
	}
}
